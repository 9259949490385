/*=======  ROOM Tab CSS  =======*/
.room-type-section {
	position: relative;
	z-index: 1;
	background-size: 100% 100%;
	background-position: center;
	&::before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		background-image: url(../img/bg/brush.png);
		z-index: -1;
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.room-filter {
		text-align: right;
		@media #{$md} {
			margin-top: 30px;
		}
		@media #{$sm} {
			margin-top: 30px;
		}
		li a {
			display: inline-block;
			font-size: 16px;
			font-weight: 700;
			text-transform: capitalize;
			border: 2px solid transparent;
			padding: 20px 30px;
			cursor: pointer;
			color: $text-color;
			@include transition(0.3s);
			border-radius: 0;

			@media #{$lg} {
				padding: 10px 20px;
			}

			@media #{$lsm} {
				padding: 10px 20px;
			}

			@media #{$xsm} {
				padding: 8px 15px;
				font-size: 14px;
			}

			&.active,
			&:hover {
				color: $black;
				border-color: #e6e6e6;
				background-color: transparent;
			}
		}
	}

	.room-items {
		.row {
			margin-left: -7.5px;
			margin-right: -7.5px;
			& > .col,
			& > [class*='col-'] {
				padding-left: 7.5px;
				padding-right: 7.5px;
			}
		}
		.room-box {
			width: 100%;
			height: 280px;
			position: relative;
			z-index: 1;
			overflow: hidden;
			margin-top: 15px;
			&.extra-height {
				height: 575px;
				@media #{$md} {
					height: 280px;
				}
				@media #{$sm} {
					height: 280px;
				}
			}
			&::after {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					45deg,
					rgba(17, 17, 17, 0.50196) 0%,
					rgba(17, 17, 17, 0.25) 44%,
					rgba(17, 17, 17, 0) 100%
				);
				content: '';
			}
			.room-bg {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				@include transition(0.4s);
			}
			.room-content {
				position: absolute;
				left: 30px;
				bottom: 30px;
				z-index: 2;
				right: 30px;
				@media #{$lsm} {
					left: 15px;
				}
				h3 {
					font-size: 36px;
					@media #{$sm} {
						font-size: 26px;
					}
					a {
						color: $white;
					}
				}
				span {
					color: $white;
					padding-bottom: 15px;
					font-weight: 700;
					i {
						margin-right: 10px;
					}
					@media #{$sm} {
						font-size: 14px;
					}
				}
			}
			.room-link {
				border: 2px solid rgba(255, 255, 255, 0.2);
				width: 60px;
				height: 60px;
				position: absolute;
				right: 15px;
				bottom: 0;
				border-radius: 50%;
				z-index: 3;
				color: $white;
				font-size: 18px;
				line-height: 60px;
				text-align: center;
				opacity: 0;
				visibility: hidden;
				@media #{$sm} {
					height: 40px;
					width: 40px;
					text-align: center;
					right: 10px;
					line-height: 40px;
				}
				&:hover {
					background-color: $white;
					color: $black;
				}
			}
			&.extra-wide {
				.room-link {
					right: 40px;
				}
			}
			&:hover {
				.room-link {
					opacity: 1;
					visibility: visible;
					bottom: 30px;
				}
				.room-bg {
					transform: scale(1.1);
				}
			}
		}
	}
}
