/*-----------------------------------------------------------------------------------
    CSS INDEX
    ===================
    Common
    Header
    -- Off canvas menu
    -- Breadcrumb CSS
    Banner
    About
    Jewelries Tab
    Call TO Action
    Text Block
    Testimonial
    Latest Post
    Core Feature
    -- Dark Style
    Jewelries
    -- Jewelries Gird
    -- Jewelries List
    -- Jewelries Slider
      -- Jewelries Slider One
      -- Jewelries Slider Two
    -- Jewelries Details
    Counter
    Our Menu
    Contact
    -- Contact info box
    Offers
    Gallery
    News
    -- News Details
    -- Paginations
    -- Sidebars
    Booking Form
    Places
    -- Places Details
    Gold Earring
    Footer
    -- Subscibe
    -- Widgets
    -- Copy Right
    -- Back to top

-----------------------------------------------------------------------------------*/


/*=======  COMMON CSS  =======*/

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&family=Prata:ital,wght@0,400;0,700;1,400&family=Roboto:wght@400;500;700&display=swap");

.texture-1 {
    position: absolute;
    bottom: -250px;
    left: -220px;
}

html {
    width: 100%;
    overflow-x: hidden;
}

.circle-out {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, .2);
    border: 1px solid #a3a3a3;
    position: absolute;
    z-index: 39393993;
}

body {
    font-family: "Rubik", sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a3a3a3;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.6;
    background-color: #1c1b19;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.slick-initialized .slick-slide:focus,
.btn:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Prata", serif;
    font-weight: 400;
    margin: 0px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
    color: #fff;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    margin: 0px;
}

a {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a,
a:hover {
    color: #fcd462;
}

input,
select,
textarea,
select {
    border: none;
    width: 100%;
    height: 70px;
    background-color: #1c1b19;
    padding: 0 40px;
}

textarea {
    height: 250px;
    padding-top: 25px;
}

::-webkit-input-placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #a3a3a3;
}

:-ms-input-placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #a3a3a3;
}

::-ms-input-placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #a3a3a3;
}

::placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #a3a3a3;
}

.nice-select {
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    position: relative;
    border: none;
    height: 70px;
    padding-left: 50px;
    line-height: 70px;
}

.nice-select .list {
    width: 100%;
    border-radius: 0;
    background-color: #353530;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: #272626;
}

.nice-select .list li {
    line-height: 35px;
    color: #a3a3a3;
}

.nice-select .list li:hover,
.nice-select .list li.option:hover {
    background-color: #fcd462;
    color: #fff;
}

.nice-select::after {
    display: none;
}

.nice-select::before {
    content: '\f063';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    left: 30px;
    top: 50%;
    color: #fcd462;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input-group input,
.input-group select,
.input-group textarea,
.input-group select {
    padding-left: 80px;
}

.input-group label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
    display: block;
}

.input-group .icon {
    position: absolute;
    left: 40px;
    bottom: 35px;
    color: #fcd462;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
}

.input-group.textarea .icon {
    top: 25px;
    bottom: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.input-group .nice-select {
    padding-left: 80px;
}

.input-group .nice-select::before {
    left: 40px;
}

.input-group.input-group-two input,
.input-group.input-group-two select,
.input-group.input-group-two textarea,
.input-group.input-group-two select {
    height: 60px;
    padding: 0px 50px 0 30px;
    background-color: #353530;
    color: #a3a3a3;
}

.input-group.input-group-two ::-webkit-input-placeholder {
    opacity: 1;
    color: #a3a3a3;
}

.input-group.input-group-two :-ms-input-placeholder {
    opacity: 1;
    color: #a3a3a3;
}

.input-group.input-group-two ::-ms-input-placeholder {
    opacity: 1;
    color: #a3a3a3;
}

.input-group.input-group-two ::placeholder {
    opacity: 1;
    color: #a3a3a3;
}

.input-group.input-group-two .icon {
    left: auto;
    bottom: 30px;
    right: 30px;
}

.input-group.input-group-two .nice-select {
    background-color: #353530;
    padding: 0 50px 0 30px;
    height: 60px;
    line-height: 60px;
}

.input-group.input-group-two .nice-select::before {
    left: auto;
    right: 30px;
}


/*=======  COMMON CLASS  =======*/

@media (min-width: 1600px) {
    .container-custom-one {
        max-width: 1600px;
    }
}

@media (min-width: 1600px) {
    .container-custom-two {
        max-width: 1400px;
    }
}

@media (min-width: 1600px) {
    header .container-fluid.container-custom-three {
        padding-left: 65px;
        padding-right: 65px;
    }
}

.slick-slide img {
    display: inline-block;
}

.bg-black {
    background-color: #353530;
}

.text-yellow {
    color: #fcb71b;
}

.main-btn {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 48px;
    font-size: 14px;
    line-height: 60px;
    border-radius: 0px;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #a3a3a3;
    border: 2px solid #fcd462;
}

.main-btn.btn-filled {
    background-color: #fcd462;
    color: #fff;
}

.main-btn.btn-filled:hover {
    background-color: transparent;
    color: #fcd462;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.main-btn.btn-border {
    border-color: #40403b;
}

.main-btn.btn-border:hover {
    background-color: #fcd462;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}


.headerBgImage::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: #353530;
    content: '';
    opacity: 0.7;
    background-size: contain;
}

.section-title span.title-tag {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 20px;
    color: #BDA985;
    font-family: Prata, serif;
    text-shadow: -2px -1px #BDA985
}

.section-title .title-tag .titleSpan {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 20px;
    color: white;
    font-family: Prata, serif;
    margin-bottom: 0 !important;
    padding-bottom: 5px !important;
    text-shadow: none !important;
    /* text-shadow: -2px -1px #FCD462 */
}

@media (max-width: 767px) {
    .section-title span.title-tag {
        font-size: 40px;
        letter-spacing: 2px;
    }

    .about-title {
        margin-top: 60px !important;
    }
}

@media (max-width: 399px) {
    .section-title span.title-tag {
        font-size: 40px;
    }

    .about-title {
        margin-top: 60px !important;
    }
}

.section-title h2 {
    font-size: 30px;
    line-height: 1.125;
    letter-spacing: -1px;
}

.section-title h3 {
    font-size: 24px;
    line-height: 1.125;
    text-align: justify;
}

.section-title .section-title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
}

.diamond-spark.spark-1 {
    animation-name: spark1;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
}

.diamond-spark.spark-2 {
    animation-name: spark2;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
}

.diamond-spark.spark-3 {
    animation-name: spark3;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
}

.diamond-spark.spark-4 {
    animation-name: spark4;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
}

.section-title .section-title-icon:before,
.section-title .section-title-icon:after {
    content: "";
    width: 160px;
    height: 1px;
    background-color: #40403b;
}

.section-title .section-title-icon svg {
    margin: 0 15px;
    width: 45px;
    height: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-title h2 {
        font-size: 50px;
    }

    .section-title h3 {
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title h2 {
        font-size: 40px;
    }

    .section-title h3 {
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    .section-title h2 {
        font-size: 30px;
    }

    .section-title h3 {
        font-size: 20px;
    }
}

@media (max-width: 399px) {
    .section-title h2 {
        font-size: 30px;
    }

    .section-title h3 {
        font-size: 20px;
    }

}

.section-title.white-color span.title-tag {
    color: #fcd462;
}

.section-title.white-color h2 {
    color: #fff;
}

.bg-white {
    background-color: #353530 !important;
}

.section {
    position: relative;
}

.arrow-style {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.arrow-style .slick-arrow {
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 18px;
    color: #fff;
    background-color: #353530;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.bg-black .arrow-style .slick-arrow {
    background-color: #1c1b19;
}

.arrow-style .slick-arrow.next-arrow {
    margin-left: 20px;
}

.arrow-style .slick-arrow:hover,
.arrow-style .slick-arrow.next-arrow {
    background-color: #fcd462;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}


/*=======  Preloader  =======*/

.preloader {
    background-color: #353530;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader svg {
    width: 400px;
    height: 100px;
}

.preloader img {
    animation: heartbeat 2s ease-in-out infinite both;
}

@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }

    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }

    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }

    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }

    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}

.preloader .cssload-container {
    width: 60px;
    height: 60px;
    position: absolute;
    transition: all 0.4s ease-out 0s;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    transform: rotate(45deg);
}

.preloader .cssload-container div {
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 100%;
    float: left;
    margin-bottom: 12px;
    animation: scaleDot 2s ease infinite;
}

.preloader .cssload-container div:not(:nth-child(4n+4)) {
    margin-right: 12px;
}

.preloader .cssload-container div:nth-child(1) {
    animation-delay: 0;
}

.preloader .cssload-container div:nth-child(2),
.preloader .cssload-container div:nth-child(5) {
    animation-delay: 0.1s;
}

.preloader .cssload-container div:nth-child(3),
.preloader .cssload-container div:nth-child(6),
.preloader .cssload-container div:nth-child(9) {
    animation-delay: 0.2s;
}

.preloader .cssload-container div:nth-child(4),
.preloader .cssload-container div:nth-child(7),
.preloader .cssload-container div:nth-child(10),
.preloader .cssload-container div:nth-child(13) {
    animation-delay: 0.3s;
}

.preloader .cssload-container div:nth-child(8),
.preloader .cssload-container div:nth-child(11),
.preloader .cssload-container div:nth-child(14) {
    animation-delay: 0.4s;
}

.preloader .cssload-container div:nth-child(12),
.preloader .cssload-container div:nth-child(15) {
    animation-delay: 0.5s;
}

.preloader .cssload-container div:nth-child(16) {
    animation-delay: 0.6s;
}


/*=======  ANIMATION  =======*/

@keyframes spark1 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(-100px, -100px);
        opacity: 0;
    }
}

@keyframes spark2 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(100px, -100px);
        opacity: 0;
    }
}

@keyframes spark3 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(-100px, 100px);
        opacity: 0;
    }
}

@keyframes spark4 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(100px, 100px);
        opacity: 0;
    }
}

@keyframes scaleDot {
    40% {
        transform: scale(1.3) translate(-2px, -2px);
    }

    80% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}

@keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0;
    }
}

@-webkit-keyframes pulse-border-2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border-2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loading-ani1 {
    100% {
        -webkit-transform: translate(39px);
        transform: translate(39px);
        opacity: 0;
    }
}

@keyframes cssload-loading-ani1 {
    100% {
        -webkit-transform: translate(39px);
        transform: translate(39px);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loading-ani2 {
    100% {
        -webkit-transform: translate(19px);
        transform: translate(19px);
        opacity: 1;
    }
}

@keyframes cssload-loading-ani2 {
    100% {
        -webkit-transform: translate(19px);
        transform: translate(19px);
        opacity: 1;
    }
}

@-webkit-keyframes cssload-loading-ani3 {
    100% {
        -webkit-transform: translate(19px);
        transform: translate(19px);
    }
}

@keyframes cssload-loading-ani3 {
    100% {
        -webkit-transform: translate(19px);
        transform: translate(19px);
    }
}

@-webkit-keyframes zoomIn {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes zoomIn {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }

    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}

@keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }

    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}

@keyframes back-forth {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(5px, 0);
    }

    80% {
        transform: translate(0px, 0);
    }
}


/*=======  Modals  =======*/

.modal-content {
    border: 0;
    border-radius: 0;
    background-color: #353530;
}

.modal-body {
    padding: 55px 40px;
}

.modal-lg {
    max-width: 1000px;
}

.modal-body .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    z-index: 3;
}

.modal-body .close-btn a {
    font-size: 24px;
    display: block;
    width: 60px;
    height: 60px;
    color: #fff;
}

@media (max-width: 575px) {
    .modal-body {
        padding: 20px;
    }
}

@media(max-width: 991px) {
    body {
        cursor: default;
    }

    .parallax_scroll {
        transform: translate3d(0px, 0px, 0px) !important;
    }

    .circle-out {
        display: none;
    }
}

.on-load-modal .modal-dialog {
    max-width: 900px;
}

.on-load-modal .modal-dialog .modal-content .modal-content {
    position: relative;
    border-radius: 0;
    padding: 40px;
    background: #fcd462;
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.on-load-modal .modal-dialog .modal-content .modal-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1;
}

.on-load-modal .modal-header {
    padding: 0;
    border: 0;
}

.on-load-modal .modal-header button {
    text-shadow: none;
    box-shadow: none;
    color: #fff;
    opacity: 1;
    font-weight: 100;
    padding: 0;
    margin: 0 0 0 auto;
    width: 40px;
    height: 40px;
}

.on-load-modal .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.on-load-modal .modal-inner {
    width: 100%;
    max-width: 500px;
}

.on-load-modal .modal-inner .title {
    color: #fff;
    font-size: 35px;
    margin-bottom: 20px;
}

.on-load-modal .modal-inner p {
    color: #a3a3a3;
    margin-bottom: 20px;
}

.on-load-modal form button {
    margin-top: 20px;
    display: block;
    width: 100%;
}


/*=======  Topbar CSS  =======*/

.lr-topbar {
    padding-top: 10px;
}

.lr-topbar .lr-topbar-controls>ul>li {
    margin-bottom: 10px;
}

.lr-topbar .lr-topbar-controls>ul>li>a {
    color: #fff;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li>a:hover,
.lr-topbar .lr-topbar-controls>ul>li>a:hover {
    color: #fcd462;
}

.lr-topbar .lr-topbar-controls .css-yk16xz-control {
    line-height: normal;
    height: auto;
    padding: 0;
    padding-left: 20px;
    color: #fff;
}

.lr-topbar .lr-topbar-controls .css-yk16xz-control:before {
    left: 0px;
}

.lr-topbar .lr-topbar-controls .css-yk16xz-control .list {
    width: 150px;
    left: -70px;
    right: 0;
}

.lr-topbar .lr-topbar-controls.style-2>ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li {
    margin-left: 20px;
    padding-left: 20px;
    position: relative;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li:after {
    content: '';
    height: 70%;
    width: 2px;
    display: block;
    background: #40403b;
    position: absolute;
    top: 4px;
    left: 0;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li:first-child {
    padding-left: 0;
    margin-left: 0;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li:first-child:after {
    display: none;
}

.lr-topbar .lr-topbar-controls.style-2>ul>li>a {
    color: #fff;
    font-weight: 600;
}

@media (max-width: 991px) {

    .lr-topbar .lr-topbar-controls.style-2>ul,
    .lr-topbar .lr-topbar-controls>ul {
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
    }
}


/*=======  HEADER CSS  =======*/

header.header-absolute {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: transparent;
    top: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    header .container.container-custom-one {
        max-width: 100%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .container.container-custom-one {
        max-width: 100%;
    }
}

header .nav-container {
    position: relative;
}

header .nav-container .toggle {
    margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .toggle {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .toggle {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .nav-container .toggle {
        display: none;
    }
}

@media (max-width: 767px) {
    header .nav-container .toggle {
        display: none;
    }
}

header .nav-container .toggle a {
    color: #fff;
    font-size: 24px;
    padding: 10px;
}

header.plus-three span.dd-trigger {
    display: none;
}

header .nav-container .site-logo img {
    max-width: 150px;
}

.site-logo.site-logo-text a {
    display: flex;
    align-items: center;
}

.site-logo.site-logo-text a svg {
    width: 45px;
    height: 45px;
}

.site-logo.site-logo-text.primray a svg path {
    fill: #fcd462;
}

.site-logo.site-logo-text .site-logo-text {
    margin-left: 20px;
}

.site-logo.site-logo-text .site-logo-text h3,
.site-logo.site-logo-text .site-logo-text h6 {
    color: #fcd462;
}

.site-logo.site-logo-text .site-logo-text h6 {
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
}

header.plus-three .nav-container {
    justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .site-logo img {
        max-width: 100px;
    }
}

@media (max-width: 991px) {
    header.plus-three .nav-container {
        justify-content: space-between;
    }

    .site-logo.site-logo-text .site-logo-text {
        margin-left: 15px;
    }

    .site-logo.site-logo-text .site-logo-text h3 {
        font-size: 24px;
    }

    .site-logo.site-logo-text .site-logo-text h6 {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    header .nav-container .site-logo img {
        max-width: 150px;
    }

    .site-logo.site-logo-text .site-logo-text {
        margin-left: 10px;
    }

    .site-logo.site-logo-text .site-logo-text h3 {
        font-size: 22px;
    }

    .site-logo.site-logo-text .site-logo-text h6 {
        font-size: 12px;
    }
}

header .nav-container .site-logo .sticky-logo {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .nav-container .header-info {
        padding: 40px 20px 0;
    }
}

@media (max-width: 767px) {
    header .nav-container .header-info {
        padding: 40px 20px 0;
    }
}

header .nav-container .header-info .item {
    padding-left: 50px;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .nav-container .header-info .item {
        color: #fff;
    }
}

@media (max-width: 767px) {
    header .nav-container .header-info .item {
        color: #fff;
    }
}

header .nav-container .header-info .item i {
    position: absolute;
    left: 0;
    top: 6px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
    background: #f8d161;
    color: #353530;
    font-size: 14px;
}

header.sticky-active .nav-container .header-info .item i {
    background: #f8d161;
    color: #353530;
}

header .nav-container .header-info .item span {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 4px;
}

header .nav-container .header-info .item a {
    display: block;
}

header .nav-container .header-info .item .title {
    font-size: 24px;
    font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    header .nav-container .header-info .item .title {
        font-size: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .header-info .item .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .nav-container .header-info .item .title {
        color: #fff;
        font-size: 22px;
    }
}

@media (max-width: 767px) {
    header .nav-container .header-info .item .title {
        color: #fff;
        font-size: 22px;
    }
}

header .nav-container .header-info .item:not(:last-child) {
    margin-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    header .nav-container .header-info .item:not(:last-child) {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .header-info .item:not(:last-child) {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header .nav-container .header-info .item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    header .nav-container .header-info .item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

header .nav-container .menu-items ul li {
    display: inline-block;
    position: relative;
}

header .nav-container .menu-items ul li a {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    line-height: 80px;
    padding: 0px 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    header .nav-container .menu-items ul li a {
        font-size: 14px;
        padding: 0 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .nav-container .menu-items ul li a {
        padding: 0 8px;
    }
}

header .nav-container .menu-items ul li .submenu {
    position: absolute;
    left: 0;
    top: 110%;
    width: 200px;
    background-color: #353530;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: 99;
    height: auto;
}

header .nav-container .menu-items ul li .submenu li {
    display: block;
}

header .nav-container .menu-items ul li .submenu li a {
    display: block;
    padding: 8px 24px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 0;
    margin: 0 0;
    line-height: 30px !important;
    border-bottom: 1px solid rgb(117 117 117 / 70%);
    color: #a3a3a3;
}

header .nav-container .menu-items ul li .submenu li:last-child a {
    border-bottom: none;
}

header .nav-container .menu-items ul li .submenu li a.active {
    background-color: #fcd462;
    color: #fff !important;
    border-color: #fcd462;
}

header .nav-container .menu-items ul li .submenu li a:hover {
    background-color: #fcd462;
    color: #fff !important;
    border-color: #fcd462;
}

header .nav-container .menu-items ul li .submenu li .submenu {
    left: 100%;
    top: 50%;
}

header .nav-container .menu-items ul li .submenu li:hover .submenu {
    top: 0;
}

header .nav-container .menu-items ul li:hover>.submenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

header .nav-container .menu-items ul li .dd-trigger {
    display: none;
}

header .nav-container .nav-pushed-item {
    display: none;
}

header .nav-container .navbar-toggler {
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: none;
}

header .nav-container .navbar-toggler span {
    position: relative;
    background-color: #fff;
    border-radius: 3px;
    display: block;
    height: 3px;
    margin-top: 5px;
    padding: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    width: 30px;
    cursor: pointer;
    display: block;
}

header .nav-container .navbar-toggler.active span:nth-of-type(1) {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    top: 8px;
}

header .nav-container .navbar-toggler.active span:nth-of-type(2) {
    opacity: 0;
}

header .nav-container .navbar-toggler.active span:nth-of-type(3) {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    top: -8px;
}

header .nav-container .navbar-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 12;
    display: none;
}

header .nav-container .navbar-close .cross-wrap {
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: relative;
}

header .nav-container .navbar-close .cross-wrap span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 6px;
    background-color: #353530;
}

header .nav-container .navbar-close .cross-wrap span.top {
    top: 12px;
    left: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

header .nav-container .navbar-close .cross-wrap span.bottom {
    bottom: 12px;
    left: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

header .nav-container.breakpoint-on .nav-menu {
    background-color: #fcd462;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 9999;
    width: 300px;
    height: 100%;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 70px;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li {
    display: block;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li a {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff !important;
    line-height: 45px !important;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li .submenu {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    visibility: visible;
    opacity: 1;
    display: none;
    -webkit-transition: none;
    transition: none;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li .submenu li a {
    color: #fff;
    padding: 0px 20px 0 40px;
    line-height: 45px !important;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li .submenu li a:hover {
    border-color: rgba(255, 255, 255, 0.5);
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li .dd-trigger {
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

header .nav-container.breakpoint-on .nav-menu.menu-on {
    left: 0;
}

header .nav-container.breakpoint-on .nav-pushed-item,
header .nav-container.breakpoint-on .navbar-close,
header .nav-container.breakpoint-on .navbar-toggler {
    display: block;
}

header.header-two .site-logo {
    margin: 0 20px;
}

header.header-two .nav-container .toggle a,
header.header-two .nav-container .menu-items ul li a {
    color: #fff;
}

header.header-two .nav-container .navbar-toggler span {
    background-color: #353530;
}

header.header-two .header-info .item i {
    background: #fcd462;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.header-two .header-info .item i {
        background: #fff;
    }
}

@media (max-width: 767px) {
    header.header-two .header-info .item i {
        color: #fff;
    }
}

header.header-two .header-info .item a,
header.header-two .header-info .item,
header.header-two .header-info .item .title {
    color: #fff;
}

header.header-three {
    background-color: transparent;
    position: relative;
}

header.header-three.header-absolute {
    position: absolute;
    top: 0;
}

header.header-three .main-menu-area {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 99;
    height: auto;
    bottom: -120px;
}

@media (max-width: 399px) {
    header.header-three .main-menu-area {
        position: relative;
        bottom: 0;
        top: 0;
    }
}

header.header-three .main-menu-area.sticky-header.sticky-active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 999;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
}

header.header-three .main-menu-area.sticky-header.sticky-active .nav-container {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

header.header-three .main-menu-area.sticky-header.sticky-active .nav-container .menu-items ul li a {
    line-height: 100px;
}

header.header-three .header-top {
    padding-top: 15px;
    padding-bottom: 75px;
    font-family: "Roboto", sans-serif;
    display: none;
}

@media (max-width: 992px) {
    header.header-three .header-top {
        background-color: #1c1b19;
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.header-three .header-top {
        padding-bottom: 60px;
        display: none;
    }
}

@media (max-width: 767px) {
    header.header-three .header-top {
        text-align: center;
        padding-bottom: 50px;
        display: none;
    }
}

@media (max-width: 399px) {
    header.header-three .header-top {
        display: none;
        display: none;
    }
}

header.header-three .header-top,
header.header-three .header-top a {
    color: #fff;
}

@media (max-width: 767px) {
    header.header-three .header-top ul.header-top-info {
        margin-top: 10px;
    }
}

header.header-three .header-top ul.header-top-info li {
    display: inline-block;
    margin-left: 15px;
}

@media (max-width: 575px) {
    header.header-three .header-top ul.header-top-info li {
        margin-left: 5px;
    }
}

header.header-three .header-top ul.header-top-info li i {
    margin-right: 10px;
    color: #fcd462;
}

header.header-three .nav-container {
    background-color: #353530;
    padding: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header.header-three .nav-container {
        padding: 0 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.header-three .nav-container {
        padding: 10px;
    }
}

@media (max-width: 767px) {
    header.header-three .nav-container {
        padding: 10px;
    }
}

@media (max-width: 399px) {
    header.header-three .nav-container {
        padding: 10px 5px;
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    header.header-three .nav-container .site-logo img {
        max-width: 200px;
    }
}

@media (max-width: 399px) {
    header.header-three .nav-container .site-logo img {
        max-width: 150px;
    }
}

header.header-three .nav-container .menu-items ul li a {
    line-height: 120px;
}

header.header-three .nav-container .menu-items ul li .submenu li a {
    line-height: 30px;
}

header.header-three .nav-container .menu-right-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

header.header-three .nav-container .menu-right-buttons .toggle {
    margin-right: 0;
}

header.header-three .nav-container .menu-right-buttons .toggle.dropdown-btn {
    position: static;
}

header.header-three .nav-container .menu-right-buttons>div {
    margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    header.header-three .nav-container .menu-right-buttons>div {
        margin-left: 5px;
    }
}

@media (max-width: 767px) {
    header.header-three .nav-container .menu-right-buttons>div {
        margin-left: 5px;
    }
}

header.header-three .nav-container .menu-right-buttons .navbar-toggler {
    border: 2px solid rgba(133, 133, 133, 0.2);
    padding: 15px;
}

@media (max-width: 575px) {
    header.header-three .nav-container .menu-right-buttons .navbar-toggler {
        padding: 5px 10px;
    }

    header.header-three .nav-container .menu-right-buttons .navbar-toggler span {
        width: 20px;
    }
}

header.header-three .nav-container .menu-right-buttons a {
    width: 65px;
    height: 65px;
    border: 2px solid rgba(133, 133, 133, 0.2);
    text-align: center;
    line-height: 65px;
    padding: 0;
    color: #fff;
}

@media (max-width: 575px) {
    header.header-three .nav-container .menu-right-buttons a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 14px;
    }
}

header.header-three .nav-container .menu-right-buttons a:hover {
    background-color: #fcd462;
    color: #fff;
}

header.header-three .search {
    position: relative;
}

header.header-three .search .search-icon {
    position: relative;
    display: block;
}

header.header-three .search .search-icon .close-icon,
header.header-three .search .search-icon .open-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

header.header-three .search .search-icon .close-icon {
    visibility: hidden;
    opacity: 0;
}

header.header-three .search .search-icon.active .close-icon {
    visibility: visible;
    opacity: 1;
}

header.header-three .search .search-icon.active .open-icon {
    visibility: hidden;
    opacity: 0;
}

header.header-three .search .search-form {
    position: absolute;
    right: 0;
    width: 350px;
    display: none;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    top: calc(100% + 27px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.header-three .search .search-form {
        top: calc(100% + 17px);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    header.header-three .search .search-form {
        top: calc(100% + 10px);
    }
}

@media (max-width: 575px) {
    header.header-three .search .search-form {
        top: calc(100% + 20px);
        width: 250px;
    }
}

@media (max-width: 399px) {
    header.header-three .search .search-form {
        right: -100%;
        top: calc(100% + 12px);
    }
}

header.header-three .search .search-form form {
    position: relative;
}

header.header-three .search .search-form form input {
    width: 100%;
    background-color: #353530;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

@media (max-width: 575px) {
    header.header-three .search .search-form form input {
        height: 50px;
    }
}

header.header-three .search .search-form form button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 1;
    background-color: #fcd462;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

header.header-three .search .search-form form button:hover {
    background-color: #fff;
    color: #1c1b19;
}

header.inner-page.header-absolute {
    background-color: #353530;
    top: 0;
}

.inner-page.sigma-header .sigma-main-menu>.menu-item>a {
    line-height: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.inner-page.header-absolute .nav-container {
        padding: 10px 0;
    }
}

@media (max-width: 767px) {
    header.inner-page.header-absolute .nav-container {
        padding: 10px 0;
    }
}

header.inner-page.header-absolute .nav-container .menu-items ul li a {
    line-height: 130px;
}

header.sticky-header.sticky-active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #353530;
    z-index: 999;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

header.sticky-header.sticky-active .lr-topbar {
    display: none;
}

header.sticky-header.header-three.sticky-active {
    background-color: transparent;
}

header.sticky-header.header-three.sticky-active .main-menu-area {
    bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    header.sticky-header.sticky-active .nav-container {
        padding: 10px 0;
    }

    header.sticky-header.sticky-active .nav-container .site-logo img {
        max-width: 200px;
    }
}

@media (max-width: 767px) {
    header.sticky-header.sticky-active .nav-container {
        padding: 10px 0;
    }
}

header.sticky-header.sticky-active .nav-container .menu-items ul li a {
    line-height: 100px;
}

header.sticky-header.sticky-active .nav-container .site-logo .main-logo {
    display: none;
}

header.sticky-header.sticky-active .nav-container .site-logo .sticky-logo {
    display: block;
}

header.sticky-header.sticky-active.header-two .nav-container .toggle a,
header.sticky-header.sticky-active.header-two .nav-container .menu-items ul li a {
    color: #fff;
}

header.sticky-header.sticky-active.header-two .nav-container .navbar-toggler span {
    background-color: #fff;
}

header.sticky-header.sticky-active.header-two .header-info .item a,
header.sticky-header.sticky-active.header-two .header-info .item,
header.sticky-header.sticky-active.header-two .header-info .item .title {
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    header.sticky-header.sticky-active.header-two .header-info .item a,
    header.sticky-header.sticky-active.header-two .header-info .item,
    header.sticky-header.sticky-active.header-two .header-info .item .title {
        color: #fff;
    }
}

@media (max-width: 767px) {

    header.sticky-header.sticky-active.header-two .header-info .item a,
    header.sticky-header.sticky-active.header-two .header-info .item,
    header.sticky-header.sticky-active.header-two .header-info .item .title {
        color: #fff;
    }
}


/*=====sigma-header======*/

.sigma-header {
    position: relative;
}

.sigma-header .sigma-notification {
    background: #fcd462;
    color: #fff;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
    margin-right: 0;
    position: relative;
    top: -12px;
    left: 35px;
    margin-left: -20px;
}

.sigma-header .sigma-topbar-controls>li.dropdown-btn {
    position: static;
}

.sigma-header .dropdown-btn .cart-dropdown-menu {
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    padding: 15px;
    width: 350px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    right: 0;
    left: auto;
    background-color: #353530;
}

.sigma-header .cart-dropdown-menu .cart-items-box {
    display: block;
    overflow-y: auto;
    height: 300px;
}

.sigma-header .cart-dropdown-menu div.cart-items-box {
    height: auto;
    padding-top: 15px;
    overflow: hidden;
}

.sigma-header .cart-dropdown-menu .cart-item {
    border-bottom: 1px solid #40403b;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma-header .cart-dropdown-menu .cart-item:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.sigma-header .cart-dropdown-menu .cart-item span {
    font-weight: 600;
    color: #fff;
    font-family: "Prata", sans-serif;
    font-size: 14px;
}

.sigma-header .cart-dropdown-menu .cart-item.actions {
    justify-content: flex-start;
}

.sigma-header .cart-dropdown-menu .cart-item.actions button {
    margin-right: 5px;
    padding: 0 15px;
    line-height: 50px;
    height: 50px;
}

.sigma-header .cart-dropdown-menu .cart-item.actions button.btn-borderd {
    background-color: transparent;
    border-color: #40403b;
}

.sigma-header .cart-dropdown-menu .cart-item .img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.sigma-header .cart-dropdown-menu .cart-item .content {
    width: calc(100% - 90px);
}

.sigma-header .cart-dropdown-menu .cart-item .content h5 {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: normal;
}

.sigma-header .cart-dropdown-menu .cart-item .content h5 a {
    font-size: inherit;
}

header.header-three.sigma-header .nav-container .menu-right-buttons .cart-dropdown-menu a {
    width: auto;
    height: auto;
    border: none;
    text-align: left;
    line-height: normal;
    color: inherit;
}

header.header-three.sigma-header .nav-container .menu-right-buttons .cart-dropdown-menu a:hover {
    background-color: transparent;
}

.sigma-header .cart-dropdown-menu .cart-item .content p {
    color: #fcd462;
    margin-bottom: 0;
}


/*=====sigma-navigation======*/

.sigma-mobile-header .sigma-mobile-header-inner {
    padding-top: 10px;
}

.sigma-header .sigma-main-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sigma-header .sigma-main-menu>.menu-item {
    margin: 0px;
}

.sigma-header .sigma-main-menu>.menu-item>a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    line-height: 120px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Prata", sans-serif;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .sigma-header .sigma-main-menu>.menu-item>a {
        padding: 0 5px;
    }
}

.sigma-header .sigma-main-menu>.menu-item:hover>a {
    color: #fcd462;
}

.sigma-header .sigma-main-menu>.menu-item>a>i {
    margin-right: 10px;
    font-size: 15px;
}

.sigma-header .sigma-main-menu .menu-item-has-children {
    position: relative;
}

.sigma-header .sigma-main-menu .menu-item-has-children .dd-trigger {
    display: none;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu {
    position: absolute;
    top: 120%;
    left: 0;
    right: 0;
    background: #353530;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out 0s;
    width: 250px;
    z-index: 10;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.sigma-header .sigma-main-menu .menu-item-has-children:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item>a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    transition: all 0.3s ease-out 0s;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;
    color: #a3a3a3;
    line-height: 30px;
    background-color: #353530;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item:last-child>a {
    border-bottom: 0;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item:hover>a {
    background-color: #fcd462;
    color: #fff;
    border-color: #fcd462;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item-has-children>a:after {
    position: absolute;
    right: 20px;
    transform: rotate(-90deg);
    display: inline-block;
    margin-left: 0;
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}

.sigma-header .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item-has-children>.sub-menu {
    left: 100%;
    top: -0%;
}

@media(max-width:991px) {
    .sigma-header .sigma-main-menu>.menu-item>a {
        line-height: 100px;
    }

    .sigma-header .sigma-main-menu .menu-item-has-children:hover>.sub-menu {
        opacity: 1;
        visibility: visible;
        top: 0%;
    }
}

@media(max-width:991px) {
    .sigma-header .sigma-main-menu>.menu-item>a {
        line-height: 100px;
    }

    .sigma-header .sigma-main-menu .menu-item-has-children:hover>.sub-menu {
        opacity: 1;
        visibility: visible;
        top: 0%;
    }
}


/*=====sigma-megamenu======*/

.sigma-header .menu-item-has-megamenu .tab-item {
    display: none;
}

.sigma-header .menu-item-has-megamenu .tab-item.active {
    display: block;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu {
    position: static;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu {
    width: 100%;
    padding: 0 15px;
    top: 120%;
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav {
    border-bottom: 0;
    border-right: 1px solid rgb(117 117 117 / 70%);
    padding: 30px 20px;
    display: block;
    padding-right: 0;
    height: 100%;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav>.nav-item {
    margin-bottom: 0;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav>.nav-item>.nav-link {
    border: none;
    background: #353530;
    color: #a3a3a3;
    font-weight: 500;
    padding: 10px;
    border-radius: 0;
    font-family: "Prata", sans-serif;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav>.nav-item>.nav-link>i {
    font-size: 18px;
    margin-right: 10px;
    width: 10px;
    text-align: center;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigm-megamenu-nav>.nav-item>.nav-link.active {
    border-right: 3px solid #fcd462;
    color: #fcd462;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-img,
.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-navbox {
    padding: 40px 0;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-navbox .sigma-title {
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-navbox .sigma-title>a {
    font-size: 14px;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .menu-item-has-children>.sub-menu {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    top: 0;
    display: none;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu:hover>.sub-menu .menu-item-has-children>.sub-menu {
    display: block;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .menu-item-has-children>.sub-menu>.menu-item>a {
    padding: 3px 0;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .menu-item-has-children>.sub-menu>.menu-item:hover>a {
    background-color: transparent;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu:hover>.sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-image {
    text-align: center;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-megamenu-image span {
    color: #a3a3a3;
    margin-bottom: 10px;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>img {
    margin-bottom: 10px;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>p {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>a {
    font-size: 14px;
    color: #a3a3a3;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu .sigma-blog-block>a:hover {
    color: #fcd462;
}

.sigma-header .sigma-main-menu .menu-item-has-children.menu-item-has-megamenu>.sub-menu img {
    width: 100%;
}


/*=====sigma-Mobile header======*/

.sigma-mobile-header {
    display: none;
}

.sigma-mobile-header .sigma-mobile-header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.sigma-mobile-header .sigma-mobile-header-inner .site-logo {
    width: 200px;
}

.sigma-mobile-header .sigma-hamburger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 2px solid #40403b;
    justify-content: center;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn {
    transition: 0.3s;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn span {
    background: #fff;
    height: 2px;
    width: 25px;
    margin-bottom: 5px;
    display: block;
    transform: 0.3s;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn span:last-child {
    margin-bottom: 0;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn.active {
    position: relative;
    top: -4px;
    transition: 0.3s;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn.active span:first-child {
    transform: rotate(45deg);
    transition: 0.3s;
    position: relative;
    top: 7.4px;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn.active span:nth-child(2) {
    display: none;
}

.sigma-mobile-header .sigma-hamburger-menu .sigma-menu-btn.active span:last-child {
    transform: rotate(-45deg);
    transition: 0.3s;
}


/*=====Sigma Mobile Menu======*/

.sigma-mobile-menu {
    width: 300px;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    background-color: #353530;
    height: 100vh;
    z-index: 10;
    transition: 0.3s all;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
}

.sigma-mobile-menu.active {
    transform: translateX(0%);
}

.sigma-mobile-menu .sigma-main-menu {
    display: block;
    padding: 40px 0;
}

.sigma-mobile-menu .sigma-main-menu>.menu-item {
    width: 100%;
    margin: 0;
}

.sigma-mobile-menu .sigma-main-menu>.menu-item>a {
    justify-content: space-between;
    padding: 10px;
    line-height: normal !important;
}

.sigma-mobile-menu .sigma-main-menu>.menu-item.menu-item-has-children>a:after {
    display: inline-block;
    margin-left: 0;
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}

.sigma-mobile-menu .sigma-main-menu .menu-item-has-children>.sub-menu {
    position: relative;
    top: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    display: none;
}

.sigma-mobile-menu .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item-has-children>a:after {
    position: relative;
    transform: none;
    right: -10px;
    font-size: 16px;
}

.sigma-mobile-menu .sigma-main-menu .menu-item-has-children>.sub-menu>li.menu-item-has-children>.sub-menu {
    left: 0;
}

.sigma-mobile-menu .sigma-main-menu .menu-item {
    position: relative;
    padding: 0 0px 0 6px;
}

.sigma-mobile-header {
    background-color: #353530;
}

@media (max-width: 991px) {

    header.header-three .main-menu-area,
    .sigma-header .sigma-header-nav {
        display: none;
    }

    .sigma-mobile-menu,
    .sigma-mobile-header {
        display: block;
    }

    header.header-three .header-top {
        padding-bottom: 10px;
        display: none;
    }

    header.header-two .site-logo {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .sigma-header .sigma-logo {
        text-align: left;
    }
}

@media (max-width: 576px) {
    .sigma-header .sigma-topbar-controls.style-2>li {
        margin-left: 10px;
    }

    .lr-topbar-controls {
        display: none;
    }
}


/*=======  Off canvas menu  =======*/

.offcanvas-wrapper {
    position: fixed;
    width: 450px;
    top: 0;
    min-height: 100vh;
    left: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    margin-left: -450px;
}

.offcanvas-wrapper.show-offcanvas {
    margin-left: 0;
    opacity: 1;
    visibility: visible;
}

.offcanvas-wrapper .offcanvas-overly {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 98;
    background-color: #1e1e1e;
    left: 0;
    top: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
}

.offcanvas-wrapper .offcanvas-overly.show-overly {
    visibility: visible;
    opacity: 0.5;
}

.offcanvas-wrapper .offcanvas-widget {
    background-color: #353530;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 70px 40px;
    position: relative;
    z-index: 99;
}

.offcanvas-wrapper .offcanvas-widget a.offcanvas-close {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 28px;
    color: #fcd462;
}

.offcanvas-wrapper .offcanvas-widget .widget {
    margin-bottom: 50px;
    font-size: 14px;
    /* Search Widget */
    /* Nav Widget */
    /* Social Link */
}

.offcanvas-wrapper .offcanvas-widget .widget .widget-title {
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 1;
    position: relative;
    padding-left: 30px;
}

.offcanvas-wrapper .offcanvas-widget .widget .widget-title::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #fcd462;
    content: '';
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form {
    position: relative;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form input {
    width: 100%;
    background-color: #1c1b19;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 1;
    background-color: #fcd462;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form button:hover {
    background-color: #fff;
    color: #1c1b19;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li {
    line-height: 30px;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li a {
    color: #a3a3a3;
    font-weight: 700;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li a:hover {
    padding-left: 10px;
    color: #fcd462;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li .submenu {
    padding-left: 10px;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li {
    display: inline-block;
    margin-right: 5px;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li a {
    height: 40px;
    width: 40px;
    background-color: #fcd462;
    color: #fff;
    line-height: 40px;
    text-align: center;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li a:hover {
    background-color: #fff;
    color: #1c1b19;
}


/*=======  Breadcrumb CSS  =======*/

.breadcrumb-area {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    margin-top: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .breadcrumb-area {
        margin-top: 100px;
    }
}

@media (max-width: 575px) {
    .breadcrumb-area {
        margin-top: 80px;
    }
}

.breadcrumb-area::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: #353530;
    content: '';
    opacity: 0.7;
    background-size: contain;
}

.breadcrumb-area .breadcrumb-text {
    position: relative;
    padding: 140px 0;
    text-align: center;
}

.breadcrumb-area .breadcrumb-text,
.breadcrumb-area .breadcrumb-text h2.page-title {
    color: #fff;
}

.breadcrumb-area .breadcrumb-text h2.page-title {
    font-size: 72px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .breadcrumb-area .breadcrumb-text h2.page-title {
        font-size: 60px;
    }
}

@media (max-width: 575px) {
    .breadcrumb-area .breadcrumb-text h2.page-title {
        font-size: 50px;
    }
}

.breadcrumb-area .breadcrumb-text span {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-bottom: 25px;
}

@media (max-width: 767px) {
    .breadcrumb-area .breadcrumb-text span {
        letter-spacing: 2px;
    }
}

@media (max-width: 575px) {
    .breadcrumb-area .breadcrumb-text span {
        font-size: 12px;
    }
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav {
    position: absolute;
    left: 50%;
    bottom: -32px;
    background-color: #353530;
    padding: 20px 40px;
    z-index: 2;
    transform: translateX(-50%);
}

@media (max-width: 575px) {
    .breadcrumb-area .breadcrumb-text .breadcrumb-nav {
        padding: 15px 20px;
        font-size: 14px;
    }
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li {
    display: inline-block;
    font-weight: 700;
    position: relative;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li,
.breadcrumb-area .breadcrumb-text .breadcrumb-nav li a {
    color: #fff;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li a:hover {
    color: #fcd462;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li:not(:first-child) {
    padding-left: 20px;
    margin-left: 20px;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li:not(:first-child)::before {
    position: absolute;
    left: -6px;
    font-size: 7px;
    top: 50%;
    -webkit-transform: translateY(-48%) rotate(90deg);
    transform: translateY(-50%) rotate(45deg);
    content: "\f45c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: #fff;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active {
    color: #fcd462;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active::before {
    color: #fcd462;
}


/*=======  BANNER CSS  =======*/

.banner-area {
    cursor: none;
    overflow: hidden;
}

.banner-area .banner-content span.promo-tag {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area .banner-content span.promo-tag {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

@media (max-width: 767px) {
    .banner-area .banner-content span.promo-tag {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.banner-area .banner-content .title {
    font-size: 100px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: -6px;
    padding-bottom: 26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .banner-area .banner-content .title {
        font-size: 100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area .banner-content .title {
        font-size: 86px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-area .banner-content .title {
        font-size: 72px;
    }
}

@media (max-width: 575px) {
    .banner-area .banner-content .title {
        font-size: 66px;
    }
}

@media (max-width: 399px) {
    .banner-area .banner-content .title {
        font-size: 52px;
    }
}

.banner-area .banner-content ul li {
    display: inline-block;
}

.banner-area .banner-content ul li:last-child {
    margin-left: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area .banner-content ul li:last-child {
        margin-left: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area .banner-content ul li:last-child {
        margin-left: 10px;
    }
}

@media (max-width: 767px) {
    .banner-area .banner-content ul li:last-child {
        margin-left: 5px;
    }
}

@media (max-width: 399px) {
    .banner-area .banner-content ul li:last-child {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-area .banner-content .main-btn {
        padding: 0 35px;
        line-height: 55px;
    }
}

@media (max-width: 575px) {
    .banner-area .banner-content .main-btn {
        padding: 0 25px;
        line-height: 50px;
        letter-spacing: 1px;
    }
}

.banner-area .banner-thumb .single-thumb {
    overflow: hidden;
}

.banner-area .banner-thumb .single-thumb img {
    -webkit-animation: zoomIn 15s linear infinite;
    animation: zoomIn 15s linear infinite;
}

.banner-area.banner-style-one {
    padding-top: 300px;
}

.vertical-text {
    position: absolute;
    bottom: calc(50% - 90px);
    left: -30px;
    transform: translateY(50%) rotate(-90deg);
}

.vertical-text.right {
    left: auto;
    bottom: calc(50% + 140px);
    right: -30px;
    transform: translateY(50%) rotate(90deg);
}

.vertical-text ul {
    display: flex;
    flex-direction: row;
    margin-right: -155px;
}

.vertical-text ul li+li {
    margin-left: 20px;
    padding-left: 20px;
}

.vertical-text ul li+li a::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: -25px;
    opacity: .3;
    transform: translateY(-50%);
}

.vertical-text ul li a {
    color: #fff;
    position: relative;
    font-size: 16px;
}

.vertical-text ul li a:hover {
    color: #fcd462;
}

.vertical-text ul li a i {
    margin-right: 10px;
}

.vertical-text span {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.vertical-text span+span {
    margin-left: 15px;
}

@media(max-width: 1600px) {
    .vertical-text {
        left: -90px;
    }

    .vertical-text ul {
        margin-right: -140px;
    }

    .vertical-text.right {
        right: -80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one {
        padding-top: 300px;
        padding-bottom: 120px;
    }
}

@media (max-width: 767px) {
    .banner-area.banner-style-one {
        padding-top: 250px;
        padding-bottom: 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .banner-area.banner-style-one .banner-content {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area.banner-style-one .banner-content {
        padding-bottom: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content .title {
        font-size: 66px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content ul li:last-child {
        margin-left: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content .main-btn {
        padding: 0 20px;
        line-height: 50px;
        letter-spacing: 1px;
    }
}

.banner-area.banner-style-two .single-banner,
.banner-area.banner-style-three .single-banner {
    height: 950px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

@media (max-width: 767px) {

    .banner-area.banner-style-two .single-banner,
    .banner-area.banner-style-three .single-banner {
        height: 700px;
    }
}

.banner-area.banner-style-two .single-banner .banner-bg,
.banner-area.banner-style-two .single-banner .banner-overly,
.banner-area.banner-style-three .single-banner .banner-bg,
.banner-area.banner-style-three .single-banner .banner-overly {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-size: cover;
    background-position: center;
}

.banner-area.banner-style-two .single-banner .banner-overly,
.banner-area.banner-style-three .single-banner .banner-overly {
    background-color: #353530;
    z-index: -1;
    opacity: 0.6;
}

.banner-area.banner-style-two .single-banner .banner-bg,
.banner-area.banner-style-three .single-banner .banner-bg {
    -webkit-animation: zoomIn 15s linear infinite;
    animation: zoomIn 15s linear infinite;
}

.banner-area.banner-style-two .banner-content,
.banner-area.banner-style-two .banner-content h1,
.banner-area.banner-style-three .banner-content,
.banner-area.banner-style-three .banner-content h1 {
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .banner-area.banner-style-two .banner-content h1,
    .banner-area.banner-style-three .banner-content h1 {
        font-size: 90px;
    }
}

.banner-area.banner-style-two .banner-content .main-btn.btn-filled,
.banner-area.banner-style-two .banner-content .main-btn.btn-border:hover,
.banner-area.banner-style-three .banner-content .main-btn.btn-filled,
.banner-area.banner-style-three .banner-content .main-btn.btn-border:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.banner-area.banner-style-two .banner-content .main-btn.btn-filled:hover,
.banner-area.banner-style-two .banner-content .main-btn.btn-border,
.banner-area.banner-style-three .banner-content .main-btn.btn-filled:hover,
.banner-area.banner-style-three .banner-content .main-btn.btn-border {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.banner-area.banner-style-three .single-banner {
    height: auto;
    padding-top: 270px;
    padding-bottom: 125px;
}

.banner-area.banner-style-three .menu-btn {
    color: #fff;
    font-size: 24px;
}

.banner-area.banner-style-three .menu-btn .book-arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 3;
    color: #fff;
    background-color: #353530;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    transition: .3s;
    margin-left: 25px;
}

.banner-area.banner-style-three .menu-btn .book-arrow:hover {
    background-color: #fcd462;
    color: #fff;
    transform: translateX(5px);
}

.banner-area.banner-style-three .call-us {
    color: #fff;
    font-size: 20px;
    transform: rotate(270deg);
    position: absolute;
    left: -72px;
    font-weight: 600;
    top: 50%;
}

.banner-area.banner-style-three .call-us span:last-child {
    margin-left: 35px;
}

.banner-area .slick-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background-color: #353530;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 9;
}

.banner-area .slick-arrow:hover {
    background-color: #fcd462;
    color: #fff;
}

.banner-area .slick-arrow.next-arrow {
    right: 0;
    left: auto;
}

.banner-area ul.slick-dots {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-51%);
    transform: translateX(-51%);
    bottom: 30px;
}

.banner-area ul.slick-dots li {
    display: inline-block;
    margin: 0 5px;
}

.banner-area ul.slick-dots li button {
    border: none;
    font-size: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    line-height: 0;
    font-size: 0;
}

.banner-area ul.slick-dots li.slick-active button {
    background-color: #fcd462;
}


/*=======  ABOUT CSS  =======*/

.about-section {
    position: relative;
    z-index: 1;
}

.about-section.style-2 img {
    width: 100%;
}

.about-section .down-arrow-wrap {
    padding-bottom: 125px;
}

.craftsmanshipHeader {
    margin-top: 50px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .about-section .down-arrow-wrap {
        padding-bottom: 90px;
    }

    .craftsmanshipHeader {
        margin-top: 120px !important;
    }
}

@media (max-width: 767px) {
    .about-section .down-arrow-wrap {
        padding-bottom: 90px;
    }

    .about-section {
        padding-top: 0px !important;
    }

    .craftsmanshipHeader {
        margin-top: 120px !important;
    }
}

.about-section .down-arrow-wrap a.down-arrow {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    background: #fcd462;
    border-radius: 50%;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    position: relative;
}

.about-section .down-arrow-wrap a.down-arrow::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1s linear infinite;
    animation: pulse-border-2 1s linear infinite;
}

.about-section .down-arrow-wrap a.down-arrow::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
    animation: pulse-border-2 1.5s linear infinite;
}

.about-section .about-title span.title-tag span {
    color: #fcd462;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-title h2 {
        font-size: 45px;
    }
}

@media (max-width: 575px) {
    .about-section .about-title h2 {
        font-size: 30px;
    }
}

.about-section .about-features {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 35px;
    margin-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features {
        grid-column-gap: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features {
        grid-column-gap: 15px;
    }
}

@media (max-width: 767px) {
    .about-section .about-features {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 2fr;
        grid-column-gap: 15px;
        margin-top: 60px;
    }
}

@media (max-width: 575px) {
    .about-section .about-features {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 3fr;
    }
}

.about-section .about-features li {
    margin-top: 15px;
}

.about-section .about-features li a {
    background-color: #353530;
    padding: 55px 0 45px;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
    box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
    text-align: center;
    display: block;
    color: #fcd462;
    position: relative;
    z-index: 1;
}

.about-section .about-features li a,
.about-section .about-features li a i,
.about-section .about-features li a span {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.about-section .about-features li a i {
    font-size: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a i {
        font-size: 55px;
    }
}

.about-section .about-features li a img {
    max-width: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a img {
        max-width: 55px;
    }
}

.about-section .about-features li a .title {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 3px;
    padding-top: 10px;
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features li a .title {
        letter-spacing: 2px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a .title {
        letter-spacing: 1px;
    }
}

@media (max-width: 767px) {
    .about-section .about-features li a .title {
        letter-spacing: 1px;
    }
}

.about-section .about-features li a .hover-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    font-size: 75px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.about-section .about-features li a:hover {
    background: #fcd462;
    color: #fff;
}

.about-section .about-features li a:hover .title {
    color: #fff;
}

.about-section .about-features li a:hover .hover-icon {
    visibility: visible;
    opacity: 0.1;
}

.about-section .about-features.masonry-layout {
    margin-top: 0;
}

@media (max-width: 767px) {
    .about-section .about-features.masonry-layout {
        margin-top: 50px;
    }
}

.about-section .about-features.masonry-layout li {
    margin-top: 0;
}

.about-section .about-features.masonry-layout li:nth-child(2) {
    margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(2) {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(2) {
        margin-top: 50px;
    }
}

.about-section .about-features.masonry-layout li:nth-child(3) {
    margin-top: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
        margin-top: 100px;
    }
}

@media (max-width: 575px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
        margin-top: 0;
    }
}

.about-section .about-features.masonry-layout li:nth-child(4) {
    margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
        margin-top: -50px;
    }
}

@media (max-width: 575px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
        margin-top: 50px;
    }
}

.about-section .about-features-boxes .single-feature-box {
    background-color: #353530;
    padding: 50px 30px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
    text-align: center;
    font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features-boxes .single-feature-box {
        padding: 50px 18px;
    }
}

.about-section .about-features-boxes .single-feature-box .icon {
    font-size: 70px;
    margin-bottom: 20px;
    line-height: 1;
    color: #fcd462;
}

.about-section .about-features-boxes .single-feature-box h4 {
    margin-bottom: 20px;
    font-size: 30px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features-boxes .single-feature-box h4 {
        font-size: 24px;
    }
}

.about-section .about-features-boxes .single-feature-box.only-bg {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 315px;
    padding: 0;
    background-size: cover;
    background-position: center;
}

.about-section .about-features-boxes .single-feature-box.dark {
    background-color: #353530;
}

.about-section .about-features-boxes .single-feature-box.dark,
.about-section .about-features-boxes .single-feature-box.dark h4,
.about-section .about-features-boxes .single-feature-box.dark h4 a {
    color: #fff;
}

.about-section .about-features-boxes .single-feature-box.dark a:hover {
    color: #fcd462;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .abour-text {
        padding: 0;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .about-section .abour-text {
        padding: 0;
        margin-top: 50px;
    }
}

.about-section .about-text-box {
    background-color: #353530;
    padding: 30px;
    margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-text-box {
        padding: 50px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-text-box {
        padding: 50px;
    }
}

@media (max-width: 575px) {
    .about-section .about-text-box {
        padding: 40px 20px;
    }
}

.about-section .about-text-box .about-text {
    font-size: 14px;
    padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-text-box .about-text {
        padding: 0;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .about-section .about-text-box .about-text {
        padding: 0;
        margin-top: 50px;
    }
}

.about-section .about-text-box .about-text span {
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.about-section .about-text-box .about-text .customSpan {
    font-weight: 400;
    font-size: 14px !important;
    text-transform: none !important;
    margin-bottom: 15px;
    font-style: italic;
    margin: 0;
}

.about-section .about-text-box .about-text h3 {
    font-size: 48px;
    margin-bottom: 30px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-text-box .about-text h3 {
        font-size: 38px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-text-box .about-text h3 {
        font-size: 34px;
    }
}

@media (max-width: 575px) {
    .about-section .about-text-box .about-text h3 {
        font-size: 32px;
    }
}

.about-section .about-text-box .about-text .main-btn {
    margin-top: 30px;
}

@media (max-width: 575px) {
    .about-section .about-text-box .about-text .main-btn {
        padding: 0 35px;
    }
}

.about-section .about-right-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.about-section .about-right-bottom .about-bottom-img {
    position: relative;
}

.about-section .about-right-bottom .about-bottom-img::before {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/icon/pattern-04.png);
    content: '';
    background-size: cover;
    background-position: center;
}


/*=======  ROOM Tab CSS  =======*/

.room-type-section {
    position: relative;
    z-index: 1;
    background-size: 100% 100%;
    background-position: center;
}

.room-type-section::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #353530;
    opacity: 0.6;
    z-index: -1;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.room-type-section .room-filter {
    text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-type-section .room-filter {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .room-type-section .room-filter {
        margin-top: 30px;
    }
}

.slick-dots li {
    height: auto;
    width: auto;
    margin: 0;
}

.slick-dots li button::before {
    display: none;
}

.slick-dots li button {
    padding: 0;
}

.room-type-section .room-filter .nav-item .nav-link {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    border: 2px solid transparent;
    padding: 20px 25px;
    cursor: pointer;
    color: #a3a3a3;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-type-section .room-filter .nav-item .nav-link {
        padding: 10px 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-type-section .room-filter .nav-item .nav-link {
        padding: 10px 20px;
    }
}

@media (max-width: 575px) {
    .room-type-section .room-filter .nav-item .nav-link {
        padding: 8px 15px;
        font-size: 14px;
    }
}

.room-type-section .room-filter .nav-item .nav-link.active,
.room-type-section .room-filter .nav-item .nav-link:hover {
    color: #fff;
    border-color: #e6e6e6;
    background-color: transparent;
}

.room-type-section .room-items .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.room-type-section .room-items .row>.col,
.room-type-section .room-items .row>[class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.room-type-section .room-items .room-box {
    width: 100%;
    height: 280px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: 15px;
}

.room-type-section .room-items .room-box.extra-height {
    height: 575px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-type-section .room-items .room-box.extra-height {
        height: 280px;
    }
}

@media (max-width: 767px) {
    .room-type-section .room-items .room-box.extra-height {
        height: 280px;
    }
}

.room-type-section .room-items .room-box::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(17, 17, 17, 0.50196) 0%, rgba(17, 17, 17, 0.25) 44%, rgba(17, 17, 17, 0) 100%);
    content: '';
}

.room-type-section .room-items .room-box .room-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.room-type-section .room-items .room-box .room-content {
    position: absolute;
    left: 30px;
    bottom: 30px;
    z-index: 2;
    right: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-type-section .room-items .room-box .room-content {
        left: 15px;
    }
}

.room-type-section .room-items .room-box .room-content h3 {
    font-size: 36px;
}

.room-type-section.style-2 .room-items .room-box .room-content h3 {
    margin-bottom: 10px;
}

.room-type-section.style-2 .room-items .room-box .room-content p {
    max-width: 80%;
}

@media (max-width: 767px) {
    .room-type-section .room-items .room-box .room-content h3 {
        font-size: 26px;
    }
}

.room-type-section .room-items .room-box .room-content h3 a {
    color: #fff;
}

.room-type-section .room-items .room-box .room-content span {
    color: #fff;
    padding-bottom: 15px;
    font-weight: 700;
}

.room-type-section .room-items .room-box .room-content span i {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .room-type-section .room-items .room-box .room-content span {
        font-size: 14px;
    }
}

.room-type-section .room-items .room-box .room-link {
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 60px;
    height: 60px;
    position: absolute;
    right: 15px;
    bottom: 0;
    border-radius: 50%;
    z-index: 3;
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

@media (max-width: 767px) {
    .room-type-section .room-items .room-box .room-link {
        height: 40px;
        width: 40px;
        text-align: center;
        right: 10px;
        line-height: 40px;
    }
}

.room-type-section .room-items .room-box .room-link:hover {
    background-color: #353530;
    color: #fff;
}

.room-type-section .room-items .room-box.extra-wide .room-link {
    right: 40px;
}

.room-type-section .room-items .room-box:hover .room-link {
    opacity: 1;
    visibility: visible;
    bottom: 30px;
}

.room-type-section .room-items .room-box:hover .room-bg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/*=======  CALL TO ACTIONS  =======*/

.cta-section .cta-inner {
    padding: 100px 0;
    background-color: #353530;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.cta-section .cta-inner .cta-text p {
    font-size: 20px;
    line-height: 1.6;
    color: #a08d69;
}

.cta-section .cta-inner .cta-text a.main-btn.btn-filled {
    margin-top: 40px;
}

.cta-section .cta-inner .cta-text a.main-btn.btn-filled:hover {
    background-color: #d2ae49;
    border-color: #d2ae49;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cta-section .cta-inner .cta-text {
        margin-top: 60px;
    }
}

@media (max-width: 767px) {
    .cta-section .cta-inner .cta-text {
        margin-top: 60px;
    }
}

.cta-section .cta-inner .cta-features .single-feature {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -ms-grid-columns: 120px 1fr;
    grid-template-columns: 120px 1fr;
    margin-bottom: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-section .cta-inner .cta-features .single-feature {
        -ms-grid-columns: 100px 1fr;
        grid-template-columns: 100px 1fr;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .cta-section .cta-inner .cta-features .single-feature {
        -ms-grid-columns: 100px 1fr;
        grid-template-columns: 100px 1fr;
    }
}

@media (max-width: 575px) {
    .cta-section .cta-inner .cta-features .single-feature {
        display: block;
    }
}

.cta-section .cta-inner .cta-features .single-feature .icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #353530;
    -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    line-height: 120px;
    font-size: 60px;
    color: #fcd462;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-section .cta-inner .cta-features .single-feature .icon {
        height: 100px;
        width: 100px;
        line-height: 100px;
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    .cta-section .cta-inner .cta-features .single-feature .icon {
        height: 100px;
        width: 100px;
        line-height: 100px;
        font-size: 40px;
    }
}

@media (max-width: 575px) {
    .cta-section .cta-inner .cta-features .single-feature .icon {
        margin-bottom: 30px;
    }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc {
    position: relative;
    padding-left: 40px;
    z-index: 1;
    font-size: 14px;
}

@media (max-width: 575px) {
    .cta-section .cta-inner .cta-features .single-feature .cta-desc {
        padding-left: 0;
    }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cta-section .cta-inner .cta-features .single-feature .cta-desc h3 {
        font-size: 26px;
    }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc .count {
    position: absolute;
    font-size: 120px;
    font-weight: 700;
    left: -32px;
    top: 0;
    line-height: 1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
    z-index: -1;
    color: transparent;
    opacity: 0.1;
}

.cta-section .cta-inner .cta-features .single-feature:hover .icon {
    background-color: #fcd462;
    color: #fff;
}

.cta-section .cta-inner .cta-features .single-feature:last-child {
    margin-bottom: 0;
}

.cta-section .cta-inner::after {
    content: '';
    position: absolute;
    left: 35px;
    right: 35px;
    bottom: -40px;
    background-color: #fcd462;
    height: 20px;
}

.cta-section .cta-inner::before {
    content: '\f108';
    font-family: 'Flaticon';
    left: -60px;
    bottom: -60px;
    line-height: 1;
    color: #f6f6f6;
    font-size: 350px;
    opacity: .1;
    position: absolute;
}

@media (max-width: 575px) {
    .cta-section .cta-inner .cta-features .single-feature .cta-desc .count {
        left: 0;
        top: -75px;
    }

    .cta-section .cta-inner::before {
        content: none;
    }
}


/*=======  Call TO Action Two  =======*/

.room-gallery-cta {
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    background-attachment: fixed;
}

.room-gallery-cta::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #fff;
    opacity: 0.7;
    content: '';
}

.room-gallery-cta ul li {
    display: inline-block;
    margin: 0px 10px;
}

@media (max-width: 767px) {
    .room-gallery-cta ul li {
        margin: 0 5px;
    }
}

@media (max-width: 767px) {
    .room-gallery-cta ul li .main-btn {
        padding: 0 25px;
        letter-spacing: 1px;
    }
}

@media (max-width: 399px) {
    .room-gallery-cta ul li .main-btn {
        padding: 0 15px;
        letter-spacing: 1px;
        font-size: 12px;
    }
}

.room-gallery-cta ul li .main-btn.btn-filled,
.room-gallery-cta ul li .main-btn.btn-border:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.room-gallery-cta ul li .main-btn.btn-filled:hover,
.room-gallery-cta ul li .main-btn.btn-border {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.room-gallery-cta .cta-text h2 {
    font-size: 80px;
    line-height: 1.125;
    letter-spacing: -3px;
    color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 72px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 52px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 42px;
    }
}

@media (max-width: 575px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 32px;
    }
}

@media (max-width: 399px) {
    .room-gallery-cta .cta-text h2 {
        font-size: 28px;
    }
}

.room-gallery-cta .cta-text span {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 20px;
    color: #fcd462;
}

.room-gallery-cta .rotate-images img {
    position: absolute;
    z-index: 1;
    bottom: 0;
    -webkit-transform: rotate(-37deg);
    transform: rotate(-37deg);
    left: 3%;
    z-index: -1;
}

@media (max-width: 767px) {
    .room-gallery-cta .rotate-images img {
        max-width: 150px;
    }
}

@media (max-width: 399px) {
    .room-gallery-cta .rotate-images img {
        max-width: 100px;
    }
}

.room-gallery-cta .rotate-images img.rotate-image-one {
    left: 8%;
    top: 0;
    bottom: auto;
    max-width: 260px;
    -webkit-transform: rotate(-37deg);
    transform: rotate(-37deg);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .room-gallery-cta .rotate-images img.rotate-image-one {
        left: 2%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gallery-cta .rotate-images img.rotate-image-one {
        left: 2%;
        max-width: 220px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-gallery-cta .rotate-images img.rotate-image-one {
        left: 1%;
        max-width: 180px;
    }
}

@media (max-width: 767px) {
    .room-gallery-cta .rotate-images img.rotate-image-one {
        left: 1%;
        max-width: 150px;
    }
}

@media (max-width: 399px) {
    .room-gallery-cta .rotate-images img.rotate-image-one {
        max-width: 100px;
    }
}

.room-gallery-cta .rotate-images img.rotate-image-three {
    right: 5%;
    left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .room-gallery-cta .rotate-images img.rotate-image-three {
        left: auto;
        right: 2%;
        max-width: 250px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gallery-cta .rotate-images img.rotate-image-three {
        left: auto;
        right: 2%;
        max-width: 250px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-gallery-cta .rotate-images img.rotate-image-three {
        left: auto;
        right: 1%;
        max-width: 200px;
    }
}

@media (max-width: 767px) {
    .room-gallery-cta .rotate-images img.rotate-image-three {
        left: auto;
        right: 1%;
        max-width: 150px;
    }
}

@media (max-width: 399px) {
    .room-gallery-cta .rotate-images img.rotate-image-three {
        max-width: 100px;
    }
}


/*=======  TEXT BLOCK  =======*/

.text-block.with-bg {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.text-block.with-bg::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #353530;
    z-index: -1;
    opacity: 0.94;
}

.text-block.bg-black p,
.text-block.bg-black .section-title h2,
.section-title h3 {
    color: #fff;
}

.text-block.bg-black .section-title .title-tag {
    color: #fcd462;
}

.text-block.bg-black .main-btn.btn-filled {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-block.bg-black .main-btn.btn-filled:hover {
    color: #fff;
}

.text-block.with-pattern {
    position: relative;
    z-index: 1;
}

.text-block.with-pattern .pattern-wrap {
    position: absolute;
    background: #fcd462;
    right: 0;
    height: 100%;
    z-index: -1;
    width: 30%;
    top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .text-block.with-pattern .pattern-wrap {
        width: 100%;
        height: 320px;
    }
}

@media (max-width: 767px) {
    .text-block.with-pattern .pattern-wrap {
        width: 100%;
        height: 320px;
    }
}

.text-block.with-pattern .pattern-wrap .pattern {
    position: relative;
    width: 100%;
    height: 100%;
}

.text-block.with-pattern .pattern-wrap .pattern::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(../img/icon/pattern-03.png);
    content: '';
    background-size: cover;
    background-position: right;
}

@media (max-width: 575px) {
    .text-block .block-text p {
        padding-right: 0;
    }

    .text-block.with-pattern .pattern-wrap .pattern::before {
        background-image: url(../img/newImages/Home/headerTop.jpg);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .text-block .mb-small {
        margin-bottom: 50px;
    }

    .text-block.with-pattern .pattern-wrap .pattern::before {
        background-image: url(../img/newImages/Home/headerTop.jpg);
    }
}

@media (max-width: 767px) {
    .text-block .mb-small {
        margin-bottom: 50px;
    }

    .text-block.with-pattern .pattern-wrap .pattern::before {
        background-image: url(../img/newImages/Home/headerTop.jpg);
    }
}


/*=======  Video  =======*/

.video-wrap {
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: initial;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 575px) {
    .video-wrap {
        height: 350px;
    }
}

.video-wrap .popup-video {
    border-radius: 50%;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    width: 60px;
    height: 60px;
    line-height: 65px;
    background-color: #353530;
    text-align: center;
    font-size: 16px;
    color: #fff;
    position: relative;
}

.HomeVideo {
    padding-top: 50% !important;
}

.video-react .video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
}

.video-react .video-react-poster {
    background-position: center !important;
    background-size: cover !important;
}

/* .video-wrap .popup-video::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1s linear infinite;
    animation: pulse-border-2 1s linear infinite;
} */

/* .video-wrap .popup-video::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
    animation: pulse-border-2 1.5s linear infinite;
} */

.video-wrap.video-wrap-two {
    height: 500px;
}

.intro-video.overlapped .video-wrap.video-wrap-two {
    position: relative;
    margin-top: -70px;
    z-index: 2;
    height: 500px;
}

@media (max-width: 575px) {
    .video-wrap.video-wrap-two {
        height: 350px;
    }
}

.video-wrap.video-wrap-two .popup-video {
    height: 120px;
    width: 120px;
    line-height: 120px;
    font-size: 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .video-wrap.video-wrap-two .popup-video {
        height: 100px;
        width: 100px;
        line-height: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.video-wrap-two .popup-video {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }
}

@media (max-width: 767px) {
    .video-wrap.video-wrap-two .popup-video {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }
}

.video-wrap.full-section {
    height: 700px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.full-section {
        height: 550px;
    }
}

@media (max-width: 767px) {
    .video-wrap.full-section {
        height: 380px;
    }
}

.video-wrap.full-section .popup-video {
    height: auto;
    width: auto;
    background-color: transparent;
}

.video-wrap.full-section .popup-video::before,
.video-wrap.full-section .popup-video::after {
    display: none;
}

.video-wrap.full-section .popup-video img {
    max-width: 115px;
    max-height: 115px;
}

@media (max-width: 767px) {
    .video-wrap.full-section .popup-video img {
        max-width: 80px;
        max-height: 80px;
    }
}

.video-wrap.video-about {
    height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.video-about {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .video-wrap.video-about {
        height: 300px;
    }
}


/*=======  Testimonial  =======*/

.testimonial-box {
    background-color: #353530;
    text-align: center;
    padding: 50px 30px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-box {
        padding: 50px 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .testimonial-box {
        padding: 50px 20px;
        font-size: 14px;
    }
}

.testimonial-box .client-img {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
    position: relative;
}

.testimonial-box .client-img img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
}

.testimonial-box .client-img .check {
    position: absolute;
    right: 0;
    top: 0;
    border: 4px solid #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fcd462;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.testimonial-box h3 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 35px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-box h3 {
        font-size: 26px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .testimonial-box h3 {
        font-size: 22px;
    }
}

.testimonial-box .clinet-post {
    font-weight: bold;
    text-transform: uppercase;
    color: #fcd462;
    margin-bottom: 30px;
    letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-box .clinet-post {
        font-size: 14px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-box .clinet-post {
        font-size: 12px;
    }
}

.testimonial-box:hover {
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
}

.testimonial-slider ul.slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.testimonial-slider ul.slick-dots li {
    display: inline-block;
    cursor: pointer;
}

.testimonial-slider ul.slick-dots li button {
    font-size: 0;
    border: 0;
    background: #e7e7e7;
    width: 16px;
    height: 4px;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.testimonial-slider ul.slick-dots li.slick-active button {
    width: 26px;
    background-color: #fcd462;
}


/*=======  Latest Post   =======*/

.latest-news .latest-post-box .post-img {
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.section-fw {
    width: 100%;
    padding: 0 75px;
}

@media (max-width: 767px) {
    .latest-news .latest-post-box .post-img {
        height: 220px;
    }
}

.latest-news .latest-post-box .post-desc {
    background-color: #353530;
    padding: 40px 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-news .latest-post-box .post-desc {
        padding: 35px 25px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-news .latest-post-box .post-desc {
        padding: 35px 18px;
        font-size: 14px;
    }
}

@media (max-width: 399px) {
    .latest-news .latest-post-box .post-desc {
        padding: 35px 25px;
        font-size: 14px;
    }
}

.latest-news .latest-post-box .post-desc .post-meta {
    margin-bottom: 20px;
}

.latest-news .latest-post-box .post-desc .post-meta li {
    display: inline-block;
    margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-news .latest-post-box .post-desc .post-meta li {
        margin-right: 5px;
        font-size: 14px;
    }

    .section-fw {
        padding: 0 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-news .latest-post-box .post-desc .post-meta li {
        margin-right: 10px;
    }

    .section-fw {
        padding: 0 50px;
    }
}

@media (max-width: 576px) {
    .section-fw {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-news .latest-post-box .post-desc .post-meta li {
        margin-right: 5px;
        font-size: 12px;
    }
}

@media (max-width: 399px) {
    .latest-news .latest-post-box .post-desc .post-meta li {
        margin-right: 5px;
        font-size: 12px;
    }
}

.latest-news .latest-post-box .post-desc .post-meta li a {
    font-weight: 500;
    color: #a3a3a3;
}

.latest-news .latest-post-box .post-desc .post-meta li a i {
    margin-right: 10px;
}

.latest-news .latest-post-box .post-desc .post-meta li:last-child {
    margin-right: 0;
}

.latest-news .latest-post-box .post-desc h4 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 25px;
    letter-spacing: -1px;
    line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-news .latest-post-box .post-desc h4 {
        font-size: 22px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-news .latest-post-box .post-desc h4 {
        font-size: 20px;
    }
}

@media (max-width: 399px) {
    .latest-news .latest-post-box .post-desc h4 {
        font-size: 20px;
    }
}


/*=======  Blog Post   =======*/

.blog-style-2 .blog-post {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    padding: 35px 40px;
    padding-top: 80%;
}

.blog-style-2 .blog-post>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.blog-style-2 .blog-post:after {
    content: '';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #28282800 0%, #282828 100%);
    max-width: none;
    height: 100%;
}

.blog-style-2 .blog-post .blog-data .post-date {
    margin-bottom: 10px;
}

.blog-style-2 .blog-post .blog-data .post-title {
    margin-bottom: 20px;
}

.blog-style-2 .blog-post .blog-data .post-link {
    display: flex;
    align-items: center;
    color: #fff;
}

.blog-style-2 .blog-post .blog-data .post-link>span {
    transition: opacity 1s ease, width 1s ease, margin 1s ease;
    margin-left: 15px;
    white-space: nowrap;
}

.blog-style-2 .blog-post .blog-data .post-link:before {
    content: '';
    width: 40px;
    height: 2px;
    background-color: #fff;
    transition: opacity 1s ease, width 1s ease, margin 1s ease;
}

.blog-style-2 .blog-post .blog-data .post-link:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    margin-left: 15px;
    font-size: 14px;
    line-height: 0;
    position: relative;
    top: 0px;
    transition: opacity 1s ease, width 1s ease, margin 1s ease;
}

.blog-style-2 .blog-post .blog-data .post-link:hover {
    color: #fcd462;
}

.no-gutters .slick-slide {
    padding-left: 0;
    padding-right: 0;
}


/*=======  Cta   =======*/

.cta-sec {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.cta-sec:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #353530;
    z-index: -1;
    opacity: 0.6;
}

.spacer {
    height: 140px;
    width: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    z-index: -1;
}

.spacer.spacer-lg {
    height: 190px;
}

.spacer.spacer-xl {
    height: 300px;
}

.spacer.spacer-bottom {
    top: auto;
    bottom: 0;
}


/*=======  Core Feature  =======*/

.features-loop .feature-box {
    padding: 45px;
    background-color: #353530;
    font-size: 14px;
    background-color: #353530;
    border: 2px solid #40403b;
    position: relative;
    margin-top: 30px;
    /*=======  Dark Style  =======*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .features-loop .feature-box {
        padding: 45px 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .features-loop .feature-box {
        padding: 45px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .features-loop .feature-box {
        padding: 35px 25px;
    }
}

@media (max-width: 575px) {
    .features-loop .feature-box {
        padding: 45px 30px;
    }
}

.features-loop .feature-box .icon {
    margin-bottom: 30px;
    font-size: 70px;
    color: #fcd462;
    line-height: 1;
}

.features-loop .feature-box.dark-box .icon {
    font-size: 100px;
    color: #fcd462;
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0.2;
    line-height: 1;
    margin-bottom: 0;
}

.features-loop .feature-box:hover .icon i {
    -webkit-animation: hvr-buzz-out 0.75s linear 1;
    animation: hvr-buzz-out 0.75s linear 1;
}

.features-loop .feature-box h3 {
    font-size: 30px;
    margin-bottom: 30px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .features-loop .feature-box h3 {
        font-size: 24px;
    }
}

.features-loop .feature-box .count {
    line-height: 1;
    position: absolute;
    right: 0;
    top: -10px;
    font-size: 120px;
    color: #3d3c38;
}

.features-loop .feature-box.dark-box .count {
    font-size: 120px;
    position: static;
    color: #3d3c38;
    margin-bottom: 15px;
    font-family: "Prata", serif;
}

.features-loop .feature-box.with-hover-img .hover-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    visibility: visible;
    opacity: 1;
}

.features-loop .feature-box.with-hover-img:hover .hover-img {
    opacity: 0;
    visibility: hidden;
}

.features-loop .feature-box.dark-box {
    background-color: #292929;
    border: none;
    overflow: hidden;
}

.features-loop .feature-box.dark-box,
.features-loop .feature-box.dark-box h3 a {
    color: #fff;
}

.features-loop .feature-box.dark-box .count {
    color: #353530;
}


/*=======  Jewelries Gird  =======*/

.room-gird-loop .room-box .room-img-wrap {
    overflow: hidden;
    width: 100%;
    height: 240px;
}

.room-gird-loop .room-box .room-img-wrap .room-img {
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background-size: cover;
    background-position: center;
}

.room-gird-loop .room-box:hover .room-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.room-gird-loop .room-box .room-desc {
    background-color: #353530;
    font-size: 14px;
    text-align: center;
    padding: 30px 35px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gird-loop .room-box .room-desc {
        padding: 30px 25px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc {
        padding: 30px 20px;
    }
}

.room-gird-loop .room-box .room-desc .icons {
    background-color: #353530;
    padding: 15px;
    position: absolute;
    left: 25px;
    right: 25px;
    top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc .icons {
        left: 12px;
        right: 12px;
        padding: 15px 5px;
    }
}

.room-gird-loop .room-box .room-desc .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-gird-loop .room-box .room-desc .icons li {
        margin: 0 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-gird-loop .room-box .room-desc .icons li {
        margin: 0 8px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc .icons li {
        margin: 0 5px;
    }
}

@media (max-width: 399px) {
    .room-gird-loop .room-box .room-desc .icons li {
        margin: 0 5px;
    }
}

.room-gird-loop .room-box .room-desc h4 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc h4 {
        font-size: 20px;
    }
}

.room-gird-loop .room-box .room-desc span.price,
.room-gird-loop .room-box .room-desc a.book-btn {
    font-size: 16px;
    font-weight: 700;
    color: #fcd462;
    margin-top: 25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    .room-gird-loop .room-box .room-desc span.price,
    .room-gird-loop .room-box .room-desc a.book-btn {
        font-size: 14px;
    }
}

.room-gird-loop .room-box .room-desc a.book-btn {
    color: #bdbdbd;
    margin-left: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-gird-loop .room-box .room-desc a.book-btn {
        margin-left: 10px;
    }
}

.room-gird-loop .room-box .room-desc a.book-btn:hover {
    color: #fcd462;
}


/*=======  Jewelries List  =======*/

.room-list-loop .room-box .room-img-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-list-loop .room-box .room-img-wrap {
        min-height: 350px;
    }
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-img-wrap {
        min-height: 250px;
    }
}

@media (max-width: 767px) {
    .room-list-loop .room-box .room-img-wrap {
        min-height: 250px;
    }
}

.room-list-loop .room-box .room-img-wrap .room-img {
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-size: cover;
    background-position: center;
}

.room-list-loop .room-box:hover .room-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.room-list-loop .room-box .room-desc {
    background-color: #353530;
    font-size: 14px;
    padding: 50px 20px 50px 40px;
    position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 20px 50px 25px;
    }
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 30px;
    }
}

@media (max-width: 399px) {
    .room-list-loop .room-box .room-desc {
        padding: 50px 25px;
    }
}

.room-list-loop .room-box .room-desc .icons {
    background-color: #353530;
    padding: 15px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    display: inline-block;
    margin-bottom: 25px;
}

.room-list-loop .room-box .room-desc .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #fff;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-list-loop .room-box .room-desc .icons li {
        margin: 0 8px;
    }
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc .icons li {
        margin: 0 5px;
    }
}

.room-list-loop .room-box .room-desc h4 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

.room-list-loop .room-box .room-desc a.book-btn {
    font-size: 16px;
    font-weight: 700;
    color: #bdbdbd;
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
}

.room-list-loop .room-box .room-desc a.book-btn:hover {
    color: #fcd462;
}

.room-list-loop .room-box .room-desc a.book-btn::before {
    position: absolute;
    content: '';
    left: 50%;
    top: 0;
    height: 2px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 60px;
    background-color: #efefef;
}

.room-list-loop .room-box .room-desc .price {
    font-size: 40px;
    font-weight: 700;
    color: #fcd462;
    line-height: 1;
    display: block;
}

@media (max-width: 575px) {
    .room-list-loop .room-box .room-desc .price {
        margin-top: 30px;
    }
}

.room-list-loop .room-box .room-desc .price span {
    font-size: 16px;
}


/*=======  Jewelries Slider  =======*/

.room-slider {
    position: relative;
    z-index: 1;
    /*=======  Jewelries Slider One  =======*/
    /*=======  Jewelries Slider Two  =======*/
}

.room-slider .rooms-slider-one.row {
    margin-left: -20px;
    margin-right: -20px;
}

.room-slider .rooms-slider-one.row>.col {
    padding-left: 20px;
    padding-right: 20px;
}

.room-slider .rooms-slider-one .slider-img {
    width: 100%;
    height: 580px;
    background-size: cover;
    background-position: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-one .slider-img {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-one .slider-img {
        height: 450px;
    }
}

@media (max-width: 575px) {
    .room-slider .rooms-slider-one .slider-img {
        height: 400px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-content-wrap {
        margin-bottom: 100px;
    }
}

.room-slider .rooms-content-wrap .room-content-box {
    background-color: #353530;
    color: #d4d4d4;
    font-size: 14px;
    padding: 60px 50px;
    position: relative;
    z-index: 2;
    margin-top: -495px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-content-wrap .room-content-box {
        margin-top: -445px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-content-wrap .room-content-box {
        margin-top: -100px;
        padding: 50px 30px;
    }
}

@media (max-width: 575px) {
    .room-slider .rooms-content-wrap .room-content-box {
        margin-top: -50px;
        padding: 50px 30px;
    }
}

.room-slider .rooms-content-wrap .room-content-box .slider-count {
    position: absolute;
    right: 50px;
    font-size: 14px;
    font-weight: 700;
    top: 90px;
    color: #4c4c4c;
    z-index: 3;
    letter-spacing: 2px;
}

.room-slider .rooms-content-wrap .room-content-box .slider-count .current {
    color: #fcd462;
}

.room-slider .rooms-content-wrap .room-content-box .slider-count-big {
    position: absolute;
    bottom: -30px;
    right: 0;
    line-height: 1;
    font-size: 200px;
    z-index: -1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
    opacity: 0.1;
}

@media (max-width: 767px) {
    .room-slider .rooms-content-wrap .room-content-box .slider-count-big {
        font-size: 150px;
    }
}

.room-slider .rooms-content-wrap .room-content-box h3 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 20px;
}

.room-slider .rooms-content-wrap .room-content-box h3,
.room-slider .rooms-content-wrap .room-content-box h3 a {
    color: #fff;
    white-space: nowrap;
}

.room-slider .rooms-content-wrap .room-content-box .icon {
    line-height: 1;
    color: #fcd462;
    margin-bottom: 40px;
    font-size: 70px;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider {
    padding-bottom: 45px;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider .single-content {
    overflow: hidden;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots {
    position: absolute;
    left: 0;
    bottom: 0;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li {
    display: inline-block;
    cursor: pointer;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li button {
    font-size: 0;
    border: 0;
    background: #434343;
    width: 16px;
    height: 4px;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li.slick-active button {
    width: 26px;
    background-color: #fcd462;
}

.room-slider .rooms-slider-two .single-rooms-box {
    position: relative;
    margin-bottom: 130px;
    margin-top: 0px;
}

.room-slider .rooms-slider-two .single-rooms-box .room-img {
    overflow: hidden;
    width: 100%;
    height: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-img {
        height: 420px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-img {
        height: 350px;
    }
}

.room-slider .rooms-slider-two .single-rooms-box .room-img .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.room-slider .rooms-slider-two .single-rooms-box:hover .img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.room-slider .rooms-slider-two .single-rooms-box .icons {
    background-color: #353530;
    padding: 15px;
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
    visibility: visible;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.room-slider .rooms-slider-two .single-rooms-box .icons li {
    display: inline-block;
    margin: 0px 10px;
    color: #fff;
}

@media (max-width: 575px) {
    .room-slider .rooms-slider-two .single-rooms-box .icons li {
        margin: 0px 5px;
    }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: -115px;
    background-color: #353530;
    padding: 40px;
    -webkit-box-shadow: 0px 3px 8px 0px #40403ba8;
    box-shadow: 0px 3px 8px 0px #40403ba8;
    font-size: 14px;
    visibility: visible;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
        left: 20px;
        right: 20px;
        padding: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
        left: 20px;
        right: 20px;
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc {
        left: 10px;
        right: 10px;
        padding: 25px;
    }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
    font-size: 36px;
    margin-bottom: 10px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
        font-size: 32px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
        font-size: 28px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
        font-size: 26px;
    }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    text-align: right;
    font-size: 40px;
    color: #fcd462;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
        font-size: 28px;
    }
}

@media (max-width: 575px) {
    .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
        text-align: left;
        margin-top: 10px;
    }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc .price span {
    font-size: 16px;
}

.room-slider .rooms-slider-two .slick-current .single-rooms-box .room-desc,
.room-slider .rooms-slider-two .slick-current .single-rooms-box .icons {
    visibility: visible;
    opacity: 1;
}

.room-slider .rooms-slider-two .slick-arrow {
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 20px;
    color: #fff;
    background-color: #353530;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: absolute;
    top: 40%;
    z-index: 2;
    left: 22%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .slick-arrow {
        left: 10%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .slick-arrow {
        left: 5%;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .slick-arrow {
        left: 2%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
    }
}

.room-slider .rooms-slider-two .slick-arrow.next-arrow {
    margin-left: 20px;
    left: auto;
    right: 22%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
        left: auto;
        right: 10%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
        left: auto;
        right: 5%;
    }
}

@media (max-width: 767px) {
    .room-slider .rooms-slider-two .slick-arrow.next-arrow {
        left: auto;
        right: 2%;
    }
}

.room-slider .rooms-slider-two .slick-arrow:hover,
.room-slider .rooms-slider-two .slick-arrow.next-arrow {
    background-color: #fcd462;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}


/*=======  Jewelries Details  =======*/

.room-details .deatils-box {
    font-family: "Roboto", sans-serif;
}

.room-details .deatils-box .title-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px 50px;
    background-color: #353530;
    margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap {
        padding: 40px 30px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap {
        padding: 30px 20px;
        margin-right: 20px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .title-wrap {
        padding: 30px;
        margin-right: 20px;
        display: block;
    }
}

.room-details .deatils-box .title-wrap .title .room-cat {
    text-transform: uppercase;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
}

.room-details .deatils-box .title-wrap .title h2 {
    font-size: 48px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap .title h2 {
        font-size: 42px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap .title h2 {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .title-wrap .title h2 {
        font-size: 28px;
    }
}

.room-details .deatils-box .title-wrap .price {
    font-size: 40px;
    color: #fcd462;
    font-weight: 700;
    font-family: "Rubik", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .title-wrap .price {
        font-size: 35px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .title-wrap .price {
        font-size: 30px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .title-wrap .price {
        font-size: 24px;
        margin-top: 15px;
    }
}

.room-details .deatils-box .title-wrap .price span {
    font-size: 16px;
}

.room-details .deatils-box .thumb {
    margin-top: -90px;
    position: relative;
    z-index: -1;
    margin-bottom: 60px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .thumb {
        margin-top: -65px;
    }
}

.room-details .deatils-box h3.subtitle {
    font-size: 30px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f1f1f1;
}

.room-details .deatils-box .room-fearures {
    background-color: #353530;
    padding: 50px 50px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .room-fearures {
        padding: 50px 35px 30px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .room-fearures {
        padding: 50px 30px 30px;
    }
}

.room-details .deatils-box .room-fearures .room-fearures-list li {
    width: 33.33%;
    float: left;
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .room-details .deatils-box .room-fearures .room-fearures-list li {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .room-fearures .room-fearures-list li {
        width: 100%;
    }
}

.room-details .deatils-box .room-fearures .room-fearures-list li i {
    color: #fcd462;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.room-details .deatils-box .room-rules {
    background-color: #353530;
    padding: 50px 50px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .room-rules {
        padding: 50px 35px 30px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .room-rules {
        padding: 50px 30px 30px;
    }
}

.room-details .deatils-box .room-rules .room-rules-list li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
}

.room-details .deatils-box .room-rules .room-rules-list li::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fcd462;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    content: '\f00c';
}

.room-details .deatils-box .cancellation-box {
    background-color: #353530;
    padding: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .deatils-box .cancellation-box {
        padding: 50px 35px;
    }
}

@media (max-width: 575px) {
    .room-details .deatils-box .cancellation-box {
        padding: 50px 30px;
    }
}

.room-details .deatils-box .cancellation-box strong {
    color: #fff;
}

.room-details .deatils-box .room-map {
    width: 100%;
    height: 390px;
    background-color: #353530;
}

@media (max-width: 575px) {
    .room-details .deatils-box .room-map {
        height: 320px;
    }
}

.room-details .deatils-box .room-map iframe {
    width: 100%;
    height: 100%;
}

.room-details .deatils-box .related-room h3.subtitle {
    border-color: #dddddd;
}

.room-details .room-booking-form {
    background-color: #353530;
    padding: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .room-details .room-booking-form {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .room-details .room-booking-form {
        margin-top: 30px;
    }
}

.room-details .room-booking-form input,
.room-details .room-booking-form .nice-select,
.room-details .room-booking-form select {
    background-color: #1c1b19;
}

.room-details .room-booking-form .main-btn {
    height: 60px;
    line-height: 60px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
}

.room-details .room-booking-form .title {
    font-weight: 700;
    font-family: "Rubik", sans-serif;
    margin-bottom: 30px;
    padding-left: 60px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .room-booking-form .title {
        padding-left: 50px;
    }
}

@media (max-width: 399px) {
    .room-details .room-booking-form .title {
        padding-left: 50px;
    }
}

.room-details .room-booking-form .title::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40px;
    height: 2px;
    background-color: #fcd462;
    content: '';
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .room-details .room-booking-form .title::before {
        width: 30px;
    }
}

@media (max-width: 399px) {
    .room-details .room-booking-form .title::before {
        width: 30px;
    }
}


/*=======  COUNTER CSS  =======*/

.counter-section .counter-box {
    margin-bottom: 50px;
}

@media (max-width: 575px) {
    .counter-section .counter-box {
        font-size: 12px;
    }
}

.counter-section .counter-box .icon {
    margin-bottom: 30px;
}

.counter-section .counter-box .icon i {
    font-size: 70px;
    font-weight: 400;
    line-height: 1;
    color: #fcd462;
}

.counter-section .counter-box .icon img {
    max-width: 100px;
}

.counter-section .counter-box,
.counter-section .counter-box h4 {
    color: #fff;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

.counter-section .counter-box h4 {
    font-size: 60px;
    word-spacing: 0;
}

.counter-section .counter-box h4 .plus-icon {
    font-size: 16px;
}

@media (max-width: 575px) {
    .counter-section .counter-box h4 {
        font-size: 38px;
    }
}

.counter-section .counter-box .title {
    color: #999999;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.box-layout {
        font-size: 12px;
    }
}

.counter-section .counter-box.counter-box-two {
    background-color: #353530;
    padding: 30px 40px 30px 120px;
    position: relative;
    margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.counter-box-two {
        padding: 30px 20px 30px 115px;
    }
}

@media (max-width: 767px) {
    .counter-section .counter-box.counter-box-two {
        padding: 110px 25px 30px;
    }
}

.counter-section .counter-box.counter-box-two,
.counter-section .counter-box.counter-box-two h4 {
    font-weight: 400;
    color: #a3a3a3;
}

.counter-section .counter-box.counter-box-two .icon {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 60px;
    color: #fcd462;
    margin: 0;
    line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.counter-box-two .icon {
        left: 30px;
    }
}

@media (max-width: 767px) {
    .counter-section .counter-box.counter-box-two .icon {
        top: 30px;
        left: 25px;
    }
}

.counter-section .counter-box.counter-box-two h4 {
    color: #fff;
    letter-spacing: -1px;
    font-family: "Prata", serif;
}


/*=======  Our Menu  =======*/

.menu-area {
    position: relative;
    z-index: 1;
}

.menu-area.menu-nagative-gallery {
    padding-top: 115px;
    margin-bottom: 305px;
    padding-bottom: 285px;
}

.menu-area.style-2.menu-nagative-gallery {
    padding-top: 0;
    margin-bottom: 305px;
    padding-bottom: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .menu-area.menu-nagative-gallery {
        margin-bottom: 230px;
        padding-bottom: 220px;
    }

    .menu-area.style-2.menu-nagative-gallery {
        padding-top: 0;
        margin-bottom: 250px;
        padding-bottom: 200px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .menu-area.menu-nagative-gallery {
        margin-bottom: 200px;
        padding-bottom: 200px;
    }

    .menu-area.style-2.menu-nagative-gallery {
        padding-top: 0;
        margin-bottom: 200px;
        padding-bottom: 200px;
    }
}

@media (max-width: 767px) {
    .menu-area.menu-nagative-gallery {
        margin-bottom: 200px;
        padding-bottom: 200px;
    }

    .menu-area.style-2.menu-nagative-gallery {
        padding-top: 0;
        margin-bottom: 200px;
        padding-bottom: 200px;
    }
}

@media (max-width: 575px) {
    .menu-area.menu-nagative-gallery {
        margin-bottom: 230px;
        padding-bottom: 240px;
    }

    .menu-area.style-2.menu-nagative-gallery {
        padding-top: 0;
        margin-bottom: 250px;
        padding-bottom: 200px;
    }
}

.menu-area.menu-nagative-gallery .gallery-wrap {
    position: absolute;
    bottom: -190px;
    width: 100%;
    left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .menu-area.menu-nagative-gallery .gallery-wrap {
        bottom: -130px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .menu-area.menu-nagative-gallery .gallery-wrap {
        bottom: -100px;
    }
}

@media (max-width: 767px) {
    .menu-area.menu-nagative-gallery .gallery-wrap {
        bottom: -100px;
    }
}

@media (max-width: 575px) {
    .menu-area.menu-nagative-gallery .gallery-wrap {
        bottom: -150px;
    }
}

.menu-area.menu-with-shape .shape-one {
    position: absolute;
    left: 2%;
    top: -70px;
    max-width: 250px;
    z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .menu-area.menu-with-shape .shape-one {
        max-width: 180px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .menu-area.menu-with-shape .shape-one {
        max-width: 180px;
    }
}

@media (max-width: 767px) {
    .menu-area.menu-with-shape .shape-one {
        display: none;
    }
}

.menu-area.menu-with-shape .shape-two {
    position: absolute;
    right: 3%;
    bottom: 160px;
    z-index: -1;
    max-width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .menu-area.menu-with-shape .shape-two {
        max-width: 180px;
    }
}

.menu-area .menu-loop .single-menu-box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 120px;
    position: relative;
}

@media (max-width: 575px) {
    .menu-area .menu-loop .single-menu-box {
        padding-left: 0;
        padding-top: 140px;
    }
}

.menu-area .menu-loop .single-menu-box .menu-desc {
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    font-size: 14px;
    color: #fff;
    padding: 20px 85px 20px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .menu-area .menu-loop .single-menu-box .menu-desc {
        padding: 20px 50px 20px 30px;
    }
}

@media (max-width: 399px) {
    .menu-area .menu-loop .single-menu-box .menu-desc {
        padding: 20px 50px 20px 30px;
    }
}

.menu-area .menu-loop .single-menu-box .menu-desc h4 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 8px;
}

.menu-area .menu-loop .single-menu-box .menu-desc .menu-link {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 24px;
    color: #fcd462;
    z-index: 2;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    visibility: hidden;
    opacity: 0;
}

.menu-area .menu-loop .single-menu-box .menu-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 100%;
    background-size: cover;
    background-position: center;
}

@media (max-width: 575px) {
    .menu-area .menu-loop .single-menu-box .menu-img {
        height: 120px;
        left: 30px;
    }
}

.menu-area .menu-loop .single-menu-box:hover .menu-desc {
    background-color: #353530;
}

.menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
    right: 40px;
    visibility: visible;
    opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
        right: 15px;
    }
}

@media (max-width: 399px) {
    .menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
        right: 15px;
    }
}

.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc {
    background-color: #fff;
}

.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc,
.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc h4 a {
    color: #fff;
}

.gallery-wrap .gallery-popup {
    overflow: hidden;
}

.gallery-wrap .gallery-popup img {
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.gallery-wrap .gallery-popup:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/*=======  CONTACT   =======*/

.contact-part .contact-info .info-box {
    padding: 50px 45px;
    background-color: #353530;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -ms-grid-columns: 40px 1fr;
    grid-template-columns: 40px 1fr;
    margin-bottom: 30px;
    grid-column-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-part .contact-info .info-box {
        padding: 50px 30px;
        -ms-grid-columns: 30px 1fr;
        grid-template-columns: 30px 1fr;
    }
}

@media (max-width: 767px) {
    .contact-part .contact-info .info-box {
        display: block;
        padding: 30px 20px;
    }
}

.contact-part .contact-info .info-box .icon {
    font-size: 40px;
    color: #fcd462;
}

@media (max-width: 767px) {
    .contact-part .contact-info .info-box .icon {
        margin-bottom: 15px;
    }
}

.contact-part .contact-info .info-box .desc h4 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-part .contact-maps {
    height: 700px;
    width: 100%;
}

.contact-part .contact-form {
    background-color: #353530;
    padding: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-part .contact-form {
        padding: 50px;
    }
}

@media (max-width: 767px) {
    .contact-part .contact-form {
        padding: 50px;
    }
}

.contact-part .contact-form .main-btn.btn-filled {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/*=======  Offers  =======*/

.offers-area .offer-boxes-loop .offer-box {
    margin-bottom: 60px;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc {
    background-color: #353530;
    padding: 65px 60px 60px;
    margin-left: 60px;
    margin-right: 60px;
    border-top: 6px solid #fcd462;
    margin-top: -75px;
    z-index: 2;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc {
        margin-left: 30px;
        margin-right: 30px;
        padding: 65px 40px 60px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc {
        margin-left: 20px;
        margin-right: 20px;
        padding: 40px 30px 30px;
        margin-top: -50px;
    }
}

@media (max-width: 575px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc {
        margin-left: 15px;
        margin-right: 15px;
        padding: 40px 20px 30px;
        margin-top: -30px;
    }
}

@media (max-width: 399px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc {
        margin-left: 5px;
        margin-right: 5px;
        padding: 30px 15px 20px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .main-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 30px;
}

@media (max-width: 575px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .main-btn {
        padding: 0 35px;
        line-height: 40px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap {
    border-bottom: 2px solid #40403b;
    margin-bottom: 40px;
    padding-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title .room-cat {
    text-transform: uppercase;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
}

@media (max-width: 575px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title .room-cat {
        letter-spacing: 2px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
    font-size: 48px;
    letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
        font-size: 24px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
    font-size: 40px;
    color: #fcd462;
    font-weight: 700;
    text-align: right;
    line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
        font-size: 32px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
        font-size: 28px;
    }
}

@media (max-width: 575px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
        font-size: 20px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price span {
    font-size: 16px;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-text,
.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
    font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
        margin-top: 50px;
    }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature li {
    width: 50%;
    float: left;
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature li i {
    color: #fcd462;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


/*=======  Gallery  =======*/

.gallery-loop {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.gallery-loop.columns-3 {
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 767px) {
    .gallery-loop {
        grid-gap: 10px;
    }
}

@media (max-width: 575px) {
    .gallery-loop {
        grid-gap: 10px;
    }
}

.gallery-loop .single-gallery-image {
    overflow: hidden;
}

.gallery-loop .single-gallery-image img {
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.gallery-loop .single-gallery-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(3),
.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(6),
.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(9),
.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(12) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
}

.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(3) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
}

.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(6) {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4 / 5;
}

.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(9) {
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;
    grid-row: 6 / 7;
}

.gallery-loop:not(.columns-3) .single-gallery-image:nth-child(12) {
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    grid-row: 8 / 9;
}


/*=======  News   =======*/

.post-box .post-desc {
    padding: 50px;
    background-color: #353530;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .post-box .post-desc {
        padding: 40px 30px;
    }
}

@media (max-width: 575px) {
    .post-box .post-desc {
        padding: 40px 30px;
        font-size: 14px;
    }
}

@media (max-width: 399px) {
    .post-box .post-desc {
        padding: 40px 20px;
    }
}

.post-box .post-desc .cat {
    margin-bottom: 25px;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    color: #fff;
    background-color: #fcd462;
}

.post-box .post-desc .cat:hover {
    background-color: #fff;
    color: #1c1b19;
}

.post-box .post-desc h2 {
    font-size: 42px;
    margin-bottom: 20px;
    letter-spacing: -2px;
    line-height: 1;
    color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-box .post-desc h2 {
        font-size: 34px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-box .post-desc h2 {
        font-size: 32px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .post-box .post-desc h2 {
        font-size: 30px;
    }
}

@media (max-width: 575px) {
    .post-box .post-desc h2 {
        font-size: 24px;
    }
}

.post-box .post-desc .post-meta {
    margin-bottom: 30px;
}

.post-box .post-desc .post-meta li {
    display: inline-block;
    margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-box .post-desc .post-meta li {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-box .post-desc .post-meta li {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .post-box .post-desc .post-meta li {
        margin-right: 10px;
    }
}

@media (max-width: 575px) {
    .post-box .post-desc .post-meta li {
        font-size: 14px;
        margin-right: 15px;
        margin-bottom: 5px;
    }
}

.post-box .post-desc .post-meta li a {
    color: #a3a3a3;
}

.post-box .post-desc .post-meta li a:hover {
    color: #fcd462;
}

.post-box .post-desc .post-meta li a i {
    margin-right: 5px;
}

.post-box .post-desc .post-footer {
    margin-top: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.post-box .post-desc .post-footer .author {
    font-family: "Prata", serif;
    letter-spacing: -1px;
}

.post-box .post-desc .post-footer .author a {
    color: #a3a3a3;
}

.post-box .post-desc .post-footer .author img {
    margin-right: 15px;
}

.post-box .post-desc .post-footer .read-more a {
    font-size: 14px;
    color: #a3a3a3;
    font-family: "Roboto", sans-serif;
}

.post-box .post-desc .post-footer .read-more a i {
    margin-right: 5px;
}

.post-box .post-desc .post-footer .read-more a:hover {
    color: #fcd462;
}

.post-box.with-video .post-media {
    position: relative;
}

.post-box.with-video .post-media .play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    background-color: #fcd462;
    color: #fff;
    line-height: 80px;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    .post-box.with-video .post-media .play-icon {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 14px;
    }
}

.post-box.with-video .post-media .play-icon:hover {
    background-color: #d2ae49;
}

.post-box.title-with-thumb {
    position: relative;
    z-index: 1;
}

.post-box.title-with-thumb::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(../img/blog/04.jpg);
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.1;
}

.post-box.title-with-thumb .post-desc {
    background-color: transparent;
}

.post-box.title-with-thumb .post-desc .post-meta {
    margin-bottom: 0;
}

.post-box.quote-post {
    padding: 50px;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-box.quote-post {
        padding: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-box.quote-post {
        padding: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .post-box.quote-post {
        padding: 40px 25px;
    }
}

@media (max-width: 575px) {
    .post-box.quote-post {
        padding: 40px 15px;
    }
}

.post-box.quote-post::before,
.post-box.quote-post::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fcd462;
    z-index: -1;
    content: '';
}

.post-box.quote-post::after {
    background-image: url(../img/icon/pattern-05.png);
    z-index: -1;
    opacity: 0.6;
}

.post-box.quote-post .post-desc {
    border: none;
    position: relative;
    z-index: 1;
    padding: 0;
    padding-left: 115px;
    background-color: transparent;
}

.post-box.quote-post .post-desc,
.post-box.quote-post .post-desc h2,
.post-box.quote-post .post-desc a {
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-box.quote-post .post-desc {
        padding-left: 75px;
    }
}

@media (max-width: 767px) {
    .post-box.quote-post .post-desc {
        padding-left: 75px;
    }
}

@media (max-width: 399px) {
    .post-box.quote-post .post-desc {
        padding-left: 0;
        padding-top: 70px;
    }
}

.post-box.quote-post .post-desc::before {
    position: absolute;
    left: 0;
    top: 5px;
    background-image: url(../img/icon/quote.png);
    width: 75px;
    height: 90px;
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    content: '';
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-box.quote-post .post-desc::before {
        width: 50px;
    }
}

@media (max-width: 767px) {
    .post-box.quote-post .post-desc::before {
        width: 50px;
        height: 50px;
    }
}

.post-box.quote-post .post-desc .post-meta {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .post-box.quote-post .post-desc .post-meta li {
        margin-right: 5px;
    }
}

.post-box.quote-post .post-desc .post-meta li a {
    color: #fff;
}


/*=======  News Details  =======*/

.news-details-box {
    padding: 40px;
    background-color: #353530;
    font-family: "Roboto", sans-serif;
    /*=======  Comment Template  =======*/
}

@media (max-width: 767px) {
    .news-details-box {
        padding: 30px;
        font-size: 14px;
    }
}

@media (max-width: 399px) {
    .news-details-box {
        padding: 15px;
    }
}

.news-details-box .entry-content .cat {
    margin-bottom: 25px;
    padding: 10px 30px;
    display: inline-block;
    font-weight: 700;
    color: #fff;
    background-color: #fcd462;
}

.news-details-box .entry-content .cat:hover {
    background-color: #fff;
    color: #1c1b19;
}

.news-details-box .entry-content .title,
.news-details-box .entry-content .subtitle {
    margin-bottom: 30px;
    letter-spacing: -1px;
    font-family: "Prata", serif;
}

.news-details-box .entry-content h2.title {
    font-size: 36px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-content h2.title {
        font-size: 28px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .news-details-box .entry-content h2.title {
        font-size: 34px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .news-details-box .entry-content h2.title {
        font-size: 28px;
    }
}

@media (max-width: 575px) {
    .news-details-box .entry-content h2.title {
        font-size: 20px;
    }
}

@media (max-width: 399px) {
    .news-details-box .entry-content h2.title {
        font-size: 18px;
    }
}

.news-details-box .entry-content h3.subtitle {
    font-size: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-content h3.subtitle {
        font-size: 24px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .news-details-box .entry-content h3.subtitle {
        font-size: 26px;
    }
}

@media (max-width: 575px) {
    .news-details-box .entry-content h3.subtitle {
        font-size: 18px;
    }
}

.news-details-box .entry-content .gap {
    height: 80px;
}

.news-details-box .entry-content .post-meta {
    margin-bottom: 25px;
}

.news-details-box .entry-content .post-meta li {
    display: inline-block;
    margin-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-content .post-meta li {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .news-details-box .entry-content .post-meta li {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .news-details-box .entry-content .post-meta li {
        margin-right: 10px;
    }
}

@media (max-width: 575px) {
    .news-details-box .entry-content .post-meta li {
        margin-right: 15px;
        margin-bottom: 5px;
    }
}

.news-details-box .entry-content .post-meta li a {
    color: #a3a3a3;
    font-size: 14px;
}

.news-details-box .entry-content .post-meta li a:hover {
    color: #fcd462;
}

.news-details-box .entry-content .post-meta li a i {
    margin-right: 5px;
}

.news-details-box .entry-content ul.list-icon li {
    padding-left: 60px;
    position: relative;
}

@media (max-width: 767px) {
    .news-details-box .entry-content ul.list-icon li {
        padding-left: 40px;
    }
}

@media (max-width: 399px) {
    .news-details-box .entry-content ul.list-icon li {
        padding-left: 25px;
    }
}

.news-details-box .entry-content ul.list-icon li:not(:last-child) {
    margin-bottom: 10px;
}

.news-details-box .entry-content ul.list-icon li::before {
    position: absolute;
    left: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
}

@media (max-width: 767px) {
    .news-details-box .entry-content ul.list-icon li::before {
        left: 0;
    }
}

.news-details-box .entry-content blockquote {
    position: relative;
    padding: 45px 55px;
    background-color: #fcd462;
    border-radius: 0;
    z-index: 1;
    font-size: 28px;
    font-family: "Prata", serif;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    letter-spacing: -1px;
    position: relative;
}

.news-details-box .entry-content blockquote::after {
    position: absolute;
    right: 40px;
    left: 40px;
    top: 40px;
    bottom: 40px;
    content: '';
    background-image: url(../img/blog-details/quote-icon.png);
    z-index: -1;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-content blockquote {
        font-size: 22px;
        padding: 40px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-content blockquote {
        padding: 30px 20px;
        font-size: 18px;
    }
}

@media (max-width: 399px) {
    .news-details-box .entry-content blockquote {
        font-size: 16px;
    }
}

.news-details-box .entry-content blockquote span {
    display: block;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
    font-size: 14px;
    text-transform: none;
}

.news-details-box .entry-footer .tag-and-share h5 {
    font-size: 20px;
    letter-spacing: -1px;
    margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-footer .tag-and-share h5 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .tag-and-share .tag {
        margin-bottom: 30px;
    }
}

.news-details-box .entry-footer .tag-and-share .tag li {
    display: inline-block;
    margin-right: 5px;
}

.news-details-box .entry-footer .tag-and-share .tag li a {
    font-size: 12px;
    padding: 10px 20px;
    display: block;
    background-color: #1c1b19;
    text-transform: uppercase;
    color: #a3a3a3;
}

.news-details-box .entry-footer .tag-and-share .tag li a:hover {
    background-color: #fcd462;
    color: #fff;
}

.news-details-box .entry-footer .tag-and-share .share li {
    display: inline-block;
    margin-left: 25px;
}

.news-details-box .entry-footer .tag-and-share .share li a {
    color: #b5becc;
    font-size: 18px;
}

.news-details-box .entry-footer .post-nav {
    padding: 50px 0;
    border-top: 1px solid #40403b;
    border-bottom: 2px solid #40403b;
    position: relative;
}

.news-details-box .entry-footer .post-nav span.icon {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .post-nav span.icon {
        display: none;
    }
}

.news-details-box .entry-footer .post-nav .prev-post span,
.news-details-box .entry-footer .post-nav .next-post span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    color: #fcd462;
}

.news-details-box .entry-footer .post-nav .prev-post a,
.news-details-box .entry-footer .post-nav .next-post a {
    color: #fff;
    font-size: 28px;
    letter-spacing: -1px;
    font-family: "Prata", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .news-details-box .entry-footer .post-nav .prev-post a,
    .news-details-box .entry-footer .post-nav .next-post a {
        font-size: 22px;
    }
}

@media (max-width: 767px) {

    .news-details-box .entry-footer .post-nav .prev-post a,
    .news-details-box .entry-footer .post-nav .next-post a {
        font-size: 20px;
    }
}

.news-details-box .entry-footer .post-nav .prev-post {
    text-align: left;
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .post-nav .prev-post {
        margin-bottom: 30px;
    }
}

.news-details-box .entry-footer .related-post-box h3 {
    font-size: 26px;
    letter-spacing: -1px;
}

.news-details-box .entry-footer .related-post-box .thumb {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
}

.news-details-box .entry-footer .related-post-box .desc {
    font-size: 14px;
    padding: 40px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
    box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-footer .related-post-box .desc {
        padding: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .news-details-box .entry-footer .related-post-box .desc {
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .related-post-box .desc {
        padding: 30px;
    }
}

.news-details-box .entry-footer .related-post-box .desc .date {
    display: inline-block;
    margin-bottom: 10px;
    color: #a3a3a3;
}

.news-details-box .entry-footer .related-post-box .desc .date i {
    margin-right: 5px;
}

.news-details-box .entry-footer .related-post-box .desc h4 {
    font-size: 22px;
    margin-bottom: 20px;
    letter-spacing: -1px;
    color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-footer .related-post-box .desc h4 {
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .news-details-box .entry-footer .related-post-box .desc h4 {
        font-size: 22px;
    }
}

@media (max-width: 399px) {
    .news-details-box .entry-footer .related-post-box .desc h4 {
        font-size: 20px;
    }
}

.news-details-box .entry-footer .related-post-box:hover .desc {
    background-color: #1c1b19;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.news-details-box .entry-footer .author-info-box {
    padding: 40px;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -ms-grid-columns: 180px 1fr;
    grid-template-columns: 180px 1fr;
    background-color: #1c1b19;
    grid-column-gap: 35px;
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-footer .author-info-box {
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .author-info-box {
        display: block;
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .author-info-box .info-text {
        margin-top: 30px;
    }
}

.news-details-box .entry-footer .author-info-box .info-text span {
    font-weight: 700;
    margin-bottom: 5px;
    color: #fcd462;
}

.news-details-box .entry-footer .author-info-box .info-text h3 {
    font-size: 36px;
    letter-spacing: -1px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .news-details-box .entry-footer .author-info-box .info-text h3 {
        font-size: 22px;
    }
}

@media (max-width: 767px) {
    .news-details-box .entry-footer .author-info-box .info-text h3 {
        font-size: 22px;
    }
}

.news-details-box .comment-template h3.box-title {
    margin-bottom: 30px;
    letter-spacing: -1px;
    font-size: 26px;
}

@media (max-width: 767px) {
    .news-details-box .comment-template h3.box-title {
        font-size: 24px;
    }
}

.news-details-box .comment-template .comments-list li {
    padding-left: 130px;
    position: relative;
    font-size: 14px;
    padding-top: 15px;
    min-height: 130px;
}

@media (max-width: 575px) {
    .news-details-box .comment-template .comments-list li {
        padding-left: 0;
    }
}

.news-details-box .comment-template .comments-list li:not(:first-child) {
    padding-top: 45px;
    margin-top: 30px;
    border-top: 1px solid #40403b;
}

.news-details-box .comment-template .comments-list li:not(:first-child) .comment-img {
    top: 30px;
}

.news-details-box .comment-template .comments-list li .comment-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
}

.news-details-box .comment-template .comments-list li .comment-img img {
    max-width: 100px;
    max-height: 100px;
}

@media (max-width: 575px) {
    .news-details-box .comment-template .comments-list li .comment-img {
        position: unset;
        margin-bottom: 30px;
    }
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top {
    margin-bottom: 20px;
    position: relative;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top h6 {
    font-size: 18px;
    letter-spacing: -1px;
    margin-bottom: 10px;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top span.date {
    font-weight: 700;
    font-size: 12px;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top .reply-link {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px 20px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    z-index: 2;
    color: #a3a3a3;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top .reply-link i {
    margin-right: 5px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .news-details-box .comment-template .comments-list li .children {
        margin-left: -80px;
    }
}

@media (max-width: 575px) {
    .news-details-box .comment-template .comments-list li .children {
        padding-left: 30px;
    }
}

.news-details-box .comment-template .comments-list li .children li:first-child {
    padding-top: 45px;
    margin-top: 30px;
    border-top: 1px solid #40403b;
}

.news-details-box .comment-template .comments-list li .children li:first-child .comment-img {
    top: 30px;
}

.news-details-box .comment-template .comment-form {
    padding: 45px;
    background-color: #1c1b19;
}

@media (max-width: 575px) {
    .news-details-box .comment-template .comment-form {
        padding: 20px;
    }
}

@media (max-width: 399px) {
    .news-details-box .comment-template .comment-form {
        padding-left: 15px;
    }
}

.news-details-box .comment-template .comment-form textarea {
    height: 150px;
    padding-top: 25px;
}

.news-details-box .comment-template .comment-form .main-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 575px) {
    .news-details-box .comment-template .comment-form .main-btn {
        padding: 0px 30px;
    }
}


/*=======  Paginations  =======*/

.pagination-wrap {
    text-align: center;
    padding-top: 20px;
}

.pagination-wrap ul li {
    display: inline-block;
    margin: 0 5px;
}

.pagination-wrap ul li a {
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: #a3a3a3;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    background-color: #353530;
    color: #fff;
}

@media (max-width: 575px) {
    .pagination-wrap ul li {
        margin: 0;
    }

    .pagination-wrap ul li a {
        width: 38px;
        height: 38px;
        line-height: 42px;
    }
}

.pagination-wrap ul li a:hover {
    background-color: #fcd462;
    color: #fff;
}

.pagination-wrap ul li.active a {
    background-color: #fcd462;
    color: #fff;
}


/*=======  Sidebars  =======*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar {
        margin-bottom: 80px;
    }

    .column-reverse {
        flex-direction: column-reverse;
    }
}

@media (max-width: 767px) {
    .sidebar {
        margin-bottom: 80px;
    }

    .column-reverse {
        flex-direction: column-reverse;
    }
}

.sidebar .widget {
    padding: 40px;
    background-color: #353530;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget {
        padding: 40px 20px;
    }
}

@media (max-width: 399px) {
    .sidebar .widget {
        padding: 40px 20px;
    }
}

.sidebar .widget .widget-title {
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 1;
    position: relative;
    padding-left: 30px;
}

.sidebar .widget .widget-title::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #fcd462;
    content: '';
}

.sidebar .widget.about-author-widget .author-box {
    font-size: 14px;
    text-align: center;
}

.sidebar .widget.about-author-widget .author-box h6 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-top: 30px;
    padding-bottom: 20px;
}

.sidebar .widget.about-author-widget .author-box .social-icon {
    margin-top: 25px;
}

.sidebar .widget.about-author-widget .author-box .social-icon li {
    display: inline-block;
    margin: 0 5px;
}

.sidebar .widget.about-author-widget .author-box .social-icon li a {
    padding: 5px;
    color: #b3bed3;
}

.sidebar .widget.about-author-widget .author-box .social-icon li a:hover {
    color: #fcd462;
}

.sidebar .widget.search-widget form {
    position: relative;
}

.sidebar .widget.search-widget form input {
    width: 100%;
    background-color: #1c1b19;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
}

.sidebar .widget.search-widget form button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 1;
    background-color: #fcd462;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sidebar .widget.search-widget form button:hover {
    background-color: #fff;
    color: #1c1b19;
}

.sidebar .widget.popular-feeds .single-popular-feed {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -ms-grid-columns: 80px 1fr;
    grid-template-columns: 80px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #40403b;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar .widget.popular-feeds .single-popular-feed:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc h6 {
    font-size: 16px;
    margin-bottom: 10px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
    font-size: 14px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time i {
    margin-right: 5px;
}

.sidebar .widget.categories-widget ul li {
    margin-bottom: 10px;
}

.sidebar .widget.categories-widget ul li:last-child {
    margin-bottom: 0;
}

.sidebar .widget.categories-widget ul li a {
    font-size: 14px;
    padding-left: 30px;
    height: 50px;
    background-color: #1c1b19;
    display: block;
    position: relative;
    line-height: 50px;
    color: #a3a3a3;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sidebar .widget.categories-widget ul li a span {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #272626;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    color: #fff;
}

.sidebar .widget.categories-widget ul li a:hover {
    background-color: #fcd462;
    color: #fff;
}

.sidebar .widget.categories-widget ul li a:hover span {
    background-color: #26323c;
    color: #fff;
}

.sidebar .widget.socail-widget ul li {
    display: inline-block;
    margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget.socail-widget ul li {
        margin-right: 5px;
    }
}

@media (max-width: 399px) {
    .sidebar .widget.socail-widget ul li {
        margin-right: 5px;
    }
}

.sidebar .widget.socail-widget ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #a3a3a3;
    background-color: #1c1b19;
    text-align: center;
}

.sidebar .widget.socail-widget ul li a:hover {
    background-color: #fcd462;
    color: #fff;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter {
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-size: 12px;
    border-bottom: 1px solid #40403b;
    padding-left: 35px;
    position: relative;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '\f099';
    font-family: 'Font Awesome 5 Brands';
    font-size: 14px;
    color: #fcd462;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter a {
    color: #fff;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter .date {
    margin-top: 15px;
    font-weight: 500;
}

.sidebar .widget.instagram-feed-widget ul {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
}

.sidebar .widget.popular-tag-widget ul li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
}

.sidebar .widget.instagram-feed-widget ul li img,
.sidebar .widget.banner-ad-widget img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.sidebar .widget.popular-tag-widget ul li a {
    font-size: 12px;
    color: #ffffff;
    padding: 10px 20px;
    background-color: #272626;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget.popular-tag-widget ul li a {
        padding: 10px 15px;
    }
}

@media (max-width: 399px) {
    .sidebar .widget.popular-tag-widget ul li a {
        padding: 10px 15px;
    }
}

.sidebar .widget.popular-tag-widget ul li a:hover {
    background-color: #fcd462;
    color: #fff;
}

.sidebar .widget.banner-ad-widget {
    padding: 0;
    border: none;
}

.sidebar .widget.banner-ad-widget .ad-banner-content {
    padding: 30px 50px;
    background-color: #353530;
}

.sidebar .widget.banner-ad-widget .ad-banner-content .icon {
    padding: 20px 0;
    text-align: center;
    font-size: 40px;
    color: #fff;
}

.sidebar .widget.banner-ad-widget .ad-banner-content .Phone-box h4 {
    color: #fff;
    font-weight: 600;
}

.sidebar .widget.banner-ad-widget .ad-banner-content .Phone-box hr {
    background-color: #40403b;
    width: 100%;
    height: 1px;
    margin: 12px 0;
    border-top: 0;
}


/*=======  BOOKING FORM  =======*/

.booking-form {
    position: relative;
    margin-top: -70px;
    z-index: 2;
}

.booking-form.overlapped {
    margin-bottom: -70px;
}

.booking-form .booking-form-inner {
    background-color: #353530;
    padding: 10px 40px 40px;
}

.booking-form form input,
.booking-form form select,
.booking-form form .nice-select {
    background-color: transparent;
    width: 100%;
    height: 50px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #fcd462;
    line-height: 50px;
    color: #a3a3a3;
}

.booking-form form .nice-select {
    border-color: #fcd462 !important;
    padding-left: 0;
}

.booking-form form .nice-select::after {
    display: none;
}

.booking-form form .nice-select::before {
    left: 0;
}

.booking-form form label {
    color: #fff;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    display: block;
    margin: 0;
}

.booking-form form .inputs-filed {
    position: relative;
}

.booking-form form .inputs-filed input,
.booking-form form .inputs-filed .nice-select {
    padding: 0 20px;
}

.booking-form form .inputs-filed .icon {
    position: absolute;
    left: 0;
    bottom: 15px;
    font-size: 14px;
    color: #fcd462;
}

.booking-form form button {
    width: 100%;
    border: none;
    background-color: #fcd462;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    height: 60px;
    letter-spacing: 2px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.booking-form form button:hover {
    background-color: #d2ae49;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .booking-form form button {
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.booking-form.booking-form-dark {
    background-color: #353530;
    margin-top: 0;
}

.booking-form.booking-form-dark .booking-form-inner {
    background-color: transparent;
}

.booking-form.booking-form-dark form label {
    color: #fff;
}

.booking-form.boxed-layout {
    margin-top: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .booking-form.boxed-layout {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .booking-form.boxed-layout {
        margin-top: 0;
    }
}

.booking-form.boxed-layout form button {
    font-size: 12px;
    letter-spacing: 1;
}


/*------  categories-box-layout -----*/

.categories-box-layout {
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.categories-box-layout .categories-box-layout-inner {
    background-color: #353530;
    padding: 40px;
    padding-bottom: 10px;
}

.categories-box-layout .categories-box {
    margin-bottom: 30px;
    border: 2px solid #40403b;
    padding: 20px;
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.categories-box-layout .categories-box .icon {
    height: 50px;
    width: 50px;
    background: #fcd462;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c1b19;
}

.categories-box-layout .categories-box .icon>i {
    font-size: 20px;
}

.categories-box-layout .categories-box .title {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 500;
}

.categories-box-layout .categories-box .overlay-icon {
    visibility: hidden;
    opacity: 0;
    color: #fff;
    font-size: 100px;
    position: absolute;
    bottom: -20px;
    right: -20px;
    z-index: -1;
    display: flex;
    transition: .3s;
}

.categories-box-layout .categories-box p {
    color: #a3a3a3;
}

.categories-box-layout .categories-box:hover {
    background-color: #1c1b19;
    border-color: #1c1b19;
}

.categories-box-layout .categories-box:hover .title,
.categories-box-layout .categories-box:hover p {
    color: #fff;
}

.categories-box-layout .categories-box:hover .overlay-icon {
    visibility: visible;
    bottom: 0;
    right: 0;
    opacity: 0.2;
}

.categories-box-layout.overlapped {
    margin-bottom: -115px;
    margin-top: -70px;
}

.categories-box-layout.dark-layout {
    background-color: #353530;
    margin-top: 0;
}

@media (max-width: 400px) {
    .categories-box-layout .categories-box-layout-inner {
        padding: 30px;
        padding-bottom: 0;
    }
}


/*------  Category Box -----*/

.category-box-sec.bg-black {
    background-color: #1c1b19;
}

.category-box-sec .category-box-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s all;
}

.category-box-sec .category-box-wrap .category-box-image,
.category-box-sec .category-box-wrap .category-box-content {
    flex: 0 0 50%;
    max-width: 50%;
}

.category-box-sec .category-box-wrap .category-box-image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-box-sec .category-box-wrap .category-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-box-sec .category-box-wrap .category-box-content .title {
    margin-bottom: 30px;
}

.category-box-sec .category-box-wrap:hover {
    background-color: #fcd462;
}

.category-box-sec .category-box-wrap:hover .main-btn {
    background-color: #1c1b19;
    border-color: #1c1b19;
}


/*------  BOOKING FORM STYLE 2 -----*/

.book-room-style2 {
    position: relative;
    background-color: #353530;
    z-index: 1;
}

.book-room-style2:before {
    content: '\f127';
    font-family: 'Flaticon';
    left: 0;
    bottom: 0;
    line-height: 1;
    color: #e3e3e3;
    font-size: 200px;
    position: absolute;
    opacity: .1;
}

.book-room-style2:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #fcd462;
    z-index: -1;
}

.book-room-style2 .book-room-content,
.book-room-style2 .calender-box {
    padding: 56px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.book-room-style2 .book-room-content .action a {
    margin-right: 20px;
}

.book-room-style2 .book-room-content .big-title a {
    font-size: 28px;
}

.book-room-style2 .book-room-content a {
    margin-top: 15px;
    font-size: 18px;
}

.book-room-style2 .book-room-content a i {
    margin-right: 8px;
}

.book-room-style2 .calender-box ul li {
    max-width: 300px;
    float: left;
    width: 25%;
    padding-right: 3px;
    margin: 0 0 22px;
}

.book-room-style2 .calender-box ul li.small-width {
    width: 21%;
}

.book-room-style2 .calender-box ul li label {
    display: inline-block;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 12px;
}

.book-room-style2 .calender-box ul li .calender-input-box {
    position: relative;
    width: 100%;
    background-color: #353530;
}

.calender-box ul li .calender-input-box .calender-input-inner {
    padding: 30px 15px 10px;
    position: relative;
    width: 100%;
}

.calender-box ul li .calender-input-box .calender-input-inner span {
    position: absolute;
    top: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #c6c6c6;
    padding-right: 14px;
}

.calender-box ul li .calender-input-box .calender-input-inner h3 {
    display: inline-block;
    font-size: 60px;
    font-weight: 600;
    vertical-align: top;
}

@media only screen and (min-width: 575px) and (max-width: 768px) {
    .calender-box ul li .calender-input-box .calender-input-inner h3 {
        font-size: 40px;
    }

    .book-room-style2 .calender-box ul li label {
        font-size: 12px;
    }
}

.calender-box ul li .calender-input-box .calender-input-inner .next-day,
.calender-box ul li .calender-input-box .calender-input-inner .prev-day {
    font-size: 15px;
    color: #8a8a8a;
    border: 1px solid #8a8a8a;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.calender-box ul li .calender-input-box .calender-input-inner .next-day:hover,
.calender-box ul li .calender-input-box .calender-input-inner .prev-day:hover {
    background-color: #fcd462;
    color: #fff;
}

.calender-box ul li .calender-input-box .calender-input-inner .prev-day {
    margin-top: 10px;
}

.calender-box ul li .calender-input-box .calender-input-inner .calendar-controls {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 5;
    right: 10px;
    display: flex;
    flex-direction: column;
}

.book-room-style2 .calender-box ul li .calender-input-box .capacity {
    display: inline-block;
    font-size: 74.38px;
    font-weight: 600;
    vertical-align: top;
    margin-top: -3px;
    height: 75px;
    margin-bottom: 4px;
    border: none;
    padding: 0;
    line-height: 100%;
    color: #3d3935;
}

.calender-box .reserve-block span {
    margin-right: 78px;
    color: #fff;
}

.calender-box .reserve-block a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.calender-box .reserve-block a::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    transition: .6s;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    background-color: #353530;
}

.calender-box .reserve-block a:hover::before {
    width: 100%;
    opacity: 1;
    visibility: visible;
}

.calender-box .reserve-block a i {
    animation: 1s linear 0s normal none infinite back-forth;
    margin-right: 10px;
    font-size: 20px;
}

@media (max-width: 1199px) {
    .book-room-style2:after {
        position: absolute;
        content: "";
        right: 0;
        top: auto;
        width: 100%;
        height: 369px;
        background-color: #fcd462;
        z-index: -1;
        bottom: 0;
    }

    .book-room-style2:before {
        content: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .book-room-style2:after {
        height: 280px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .book-room-style2:after {
        height: 255px;
    }
}

@media (max-width: 576px) {
    .book-room-style2:after {
        content: none;
    }

    .book-room-style2 .mobile-none {
        background-color: #fcd462;
    }

    .book-room-style2 .book-room-content,
    .book-room-style2 .calender-box {
        padding-left: 0;
        padding-right: 0;
    }

    .book-room-style2 .calender-box ul li,
    .book-room-style2 .calender-box ul li.small-width {
        width: 50%;
    }
}


/*=======  Places   =======*/

.places-wrapper .places-maps {
    width: 100%;
    height: 700px;
}

.places-wrapper .gm-style .gm-style-iw-c {
    padding: 0;
    border-radius: 0;
}

.places-wrapper .gm-style .gm-style-iw-d {
    overflow: hidden !important;
    width: 290px;
}

.places-wrapper .map-info-window .info-window-img {
    width: 100%;
    height: 190px;
    background-size: cover;
    background-position: center;
}

.places-wrapper .map-info-window .info-content {
    text-align: center;
    padding: 20px 10px;
    font-size: 14px;
}

.places-wrapper .map-info-window .info-content h4 {
    margin-bottom: 5px;
}

.places-boxes .place-box .place-bg-wrap {
    overflow: hidden;
}

.places-boxes .place-box .place-bg-wrap .place-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 190px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
    .places-boxes .place-box .place-bg-wrap .place-bg {
        height: 220px;
    }
}

.places-boxes .place-box:hover .place-bg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.places-boxes .place-box .desc {
    background-color: #353530;
    padding: 20px;
    text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .places-boxes .place-box .desc {
        padding: 20px 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .places-boxes .place-box .desc {
        padding: 20px 15px;
    }
}

.places-boxes .place-box .desc h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .places-boxes .place-box .desc h4 {
        font-size: 22px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .places-boxes .place-box .desc h4 {
        font-size: 22px;
    }
}

.places-boxes .place-box .desc .time {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}


/*=======  404 and coming soon  =======*/

.error {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error svg {
    height: 90px;
    width: 300px;
    margin-bottom: 70px;
    opacity: .3;
}

.error h1 {
    font-size: 200px;
    line-height: 1;
    margin-bottom: 20px;
}

.error p {
    margin: 30px 0 70px;
}

.error .error-texts {
    max-width: 600px;
    margin: 0 auto;
}

.error form {
    max-width: 700px;
    margin: 60px auto 0;
    padding: 20px;
    background-color: rgba(28, 27, 25, .6);
}

.error form button {
    padding: 0;
    height: 100%;
}

.error .sigma_countdown-timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
}

.error .sigma_countdown-timer li {
    text-align: center;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(28, 27, 25, .6);
}

.error .sigma_countdown-timer li span {
    display: block;
    margin-bottom: 10px;
    color: #fff;
}

@media (max-width: 768px) {
    .error h1 {
        font-size: 130px;
        line-height: 1;
        margin-bottom: 20px;
    }

    .error p {
        margin: 20px 0 40px;
    }

    .error svg {
        margin-bottom: 40px;
    }
}


/*=======  Places Details  =======*/

.places-details .title-wrap .place-cat {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
    letter-spacing: 2px;
}

@media (max-width: 575px) {
    .places-details .title-wrap .place-cat {
        letter-spacing: 0;
        font-size: 12px;
    }
}

.places-details .title-wrap h2 {
    font-size: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .places-details .title-wrap h2 {
        font-size: 38px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .places-details .title-wrap h2 {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .places-details .title-wrap h2 {
        font-size: 28px;
    }
}

@media (max-width: 399px) {
    .places-details .title-wrap h2 {
        font-size: 24px;
    }
}

.places-details .title-wrap .link a {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    background-color: #353530;
    color: #a3a3a3;
    padding: 25px 45px;
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    .places-details .title-wrap .link a {
        padding: 20px 30px;
    }
}

@media (max-width: 575px) {
    .places-details .title-wrap .link a {
        padding: 15px 25px;
        letter-spacing: 0px;
    }
}

.places-details .title-wrap .link a:hover {
    background-color: #fcd462;
    color: #fff;
}


/*=======  Gold Earring CSS  =======*/

.restaurant-tab-area .restaurant-rood-list .nav-link {
    padding: 0;
    border-radius: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background-color: #353530;
    text-align: center;
    display: block;
    padding: 35px 25px;
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .restaurant-tab-area .restaurant-rood-list .nav-link {
        padding: 25px;
    }
}

.restaurant-tab-area .restaurant-rood-list .nav-link .title {
    display: block;
    font-size: 12px;
    font-weight: 700;
    padding-top: 20px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    color: #fff;
}

.restaurant-tab-area .restaurant-rood-list .nav-link i {
    color: #fcd462;
    font-size: 60px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
    .restaurant-tab-area .restaurant-rood-list .nav-link i {
        font-size: 40px;
    }
}

.restaurant-tab-area .restaurant-rood-list .nav-link.active,
.restaurant-tab-area .restaurant-rood-list .nav-link:hover {
    background-color: #fcd462;
}

.restaurant-tab-area .restaurant-rood-list .nav-link.active i,
.restaurant-tab-area .restaurant-rood-list .nav-link.active .title,
.restaurant-tab-area .restaurant-rood-list .nav-link:hover i,
.restaurant-tab-area .restaurant-rood-list .nav-link:hover .title {
    color: #fff;
}

.restaurant-tab-area .food-box {
    margin-bottom: 30px;
}

@media (max-width: 399px) {
    .restaurant-tab-area .food-box {
        margin-left: -5px;
        margin-right: -5px;
    }
}

.restaurant-tab-area .food-box .thumb {
    position: relative;
}

.restaurant-tab-area .food-box .thumb img {
    width: 100%;
}

.restaurant-tab-area .food-box .thumb .price {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #fcd462;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 8px 15px;
}

.restaurant-tab-area .food-box .desc {
    padding: 30px;
    background-color: #353530;
    position: relative;
}

.restaurant-tab-area.bg-black .food-box .desc {
    background-color: #1c1b19;
}

@media (max-width: 575px) {
    .restaurant-tab-area .food-box .desc {
        padding: 15px;
    }
}

.restaurant-tab-area .food-box .desc a.cat {
    font-size: 12px;
    color: #a3a3a3;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .restaurant-tab-area .food-box .desc a.cat {
        letter-spacing: 1px;
    }
}

@media (max-width: 399px) {
    .restaurant-tab-area .food-box .desc a.cat {
        letter-spacing: 0;
    }
}

.restaurant-tab-area .food-box .desc h4 {
    font-size: 24px;
}

@media (max-width: 575px) {
    .restaurant-tab-area .food-box .desc h4 {
        font-size: 20px;
    }
}

@media (max-width: 399px) {
    .restaurant-tab-area .food-box .desc h4 {
        font-size: 18px;
    }
}

.restaurant-tab-area .food-box .desc .link {
    color: #d2d2d2;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 18px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    visibility: hidden;
    opacity: 0;
}

.restaurant-tab-area .food-box:hover .desc .link {
    visibility: visible;
    opacity: 1;
    right: 30px;
}

@media (max-width: 575px) {
    .restaurant-tab-area .food-box:hover .desc .link {
        right: 10px;
    }
}

.feature-room-section.with-bg {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.feature-room-section.with-bg::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #353530;
    opacity: 0.6;
    z-index: -1;
}


/*=======  condos apartment  =======*/

.apartment-box {
    margin-top: 30px;
    overflow: hidden;
}

.content-box-2 {
    background-color: #353530;
    font-size: 14px;
    text-align: center;
    padding: 30px 20px 0;
    position: relative;
}

.content-box-2 .title {
    font-size: 22px;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

.content-box-2 p {
    color: #bdbdbd;
}

.content-box-2 .price {
    font-size: 16px;
    font-weight: 700;
    color: #fcd462;
    margin-top: 20px;
}

.apartment-box .image-box img {
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.apartment-box:hover .image-box img {
    transform: scale(1.1);
}


/*=======  Condos Overlay  =======*/

.condo-item {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 450px;
    z-index: 1;
}

.condo-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(40 47 54 / 62%);
    z-index: -1;
    transition: all 0.4s ease 0s;
}

.condo-item .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
}

.condo-item .title h4 {
    margin-bottom: 15px;
    margin-top: 10px;
    font-weight: 600;
}

.condo-item .main-btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 35px;
    color: #ffffff;
    line-height: 45px;
    padding: 0 25px;
}

.condo-item .title.title-hidden,
.condo-item.hotel-intro .display-on-hover {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease 0s, visibility 0.4s ease 0s;
}

.condo-item .title.title-hidden .book-btn a {
    color: #fff;
    margin-top: 15px;
}

.condo-item .title.title-hidden .book-btn a i {
    margin-right: 6px;
}

.condo-item:hover:before {
    background-color: rgb(76 98 117 / 86%);
}

.condo-item:hover .title {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease 0s, visibility 0.4s ease 0s;
}

.condo-item:hover .title.title-hidden,
.condo-item.hotel-intro:hover .display-on-hover {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s ease .4s, visibility .4s ease .4s;
}

.condo-item:hover .main-btn {
    border-color: #fff;
    color: #fff;
}


/*----- Hotel Intro ----*/

.condo-item.hotel-intro {
    height: 370px;
    margin-top: 30px;
}

.condo-item.hotel-intro .icon {
    color: #fff;
    font-size: 60px;
    line-height: 1;
}

.condo-item.hotel-intro:before {
    background-color: rgb(0 0 0 / 55%);
}

.condo-item.hotel-intro:hover:before {
    background-color: rgb(94 81 42 / 86%);
}

.condo-item.hotel-intro .title h4 {
    margin: 15px 0;
}

.condo-item.hotel-intro:hover .title {
    opacity: 1;
    visibility: visible;
}


/*=======  Team  =======*/

.team-section.has-slider .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 575px) {
    .team-section.has-slider .container-fluid {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 399px) {
    .team-section.has-slider .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.team-section .team-loop .member-box {
    position: relative;
    margin-bottom: 30px;
}

.team-section .team-loop .member-box .member-img {
    position: relative;
    overflow: hidden;
}

.team-section .team-loop .member-box .member-img img {
    width: 100%;
}

.team-section .team-loop .member-box .member-img::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0.7;
    content: '';
    z-index: 1;
}

.team-section .team-loop .member-box:hover .member-img img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.team-section .team-loop .member-box .member-info {
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 2;
}

@media (max-width: 767px) {
    .team-section .team-loop .member-box .member-info {
        left: 20px;
        bottom: 20px;
    }
}

.team-section .team-loop .member-box .member-info h3 {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
}

@media (max-width: 767px) {
    .team-section .team-loop .member-box .member-info h3 {
        font-size: 22px;
    }
}

.team-section .team-loop .member-box .member-info span {
    color: #fcd462;
    font-weight: 700;
    text-transform: uppercase;
}

.team-section .team-loop .member-box .social-links {
    padding: 12px;
    background-color: #1c1b19;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.team-section .team-loop .member-box .social-links li a {
    height: 50px;
    width: 50px;
    line-height: 54px;
    display: block;
    text-align: center;
    color: #fff;
    background-color: #212121;
}

.team-section .team-loop .member-box .social-links li a:hover {
    background-color: #fcd462;
}

.team-section .team-loop .member-box .social-links li+li a {
    margin-top: 10px;
}

.team-section .team-loop .member-box .socail-trigger {
    position: absolute;
    z-index: 2;
    font-weight: 400;
    font-size: 30px;
    background-color: #353530;
    color: #fff;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 57px;
}

.team-section .team-loop .member-box .socail-trigger:hover {
    background-color: #fcd462;
    color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .team-section .team-loop.team-slider-one .member-box .member-info {
        left: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .team-section .team-loop.team-slider-one .member-box .member-info {
        left: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-section .team-loop.team-slider-one .member-box .member-info {
        left: 15px;
        bottom: 15px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .team-section .team-loop.team-slider-one .member-box .member-info h3 {
        font-size: 22px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .team-section .team-loop.team-slider-one .member-box .member-info h3 {
        font-size: 22px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-section .team-loop.team-slider-one .member-box .member-info h3 {
        font-size: 22px;
    }
}


/*=======  Subscribe Area style2  =======*/

.Subscribe-map-sec {
    position: relative;
}

.footer-subscibe-area.style-2 {
    background-color: #353530;
}

.footer-subscibe-area.style-2 .subscribe-text .subscribe-form button {
    position: static;
    background-color: #fcd462;
    color: #fff;
    box-shadow: none;
    padding: 0 40px;
}

.footer-subscibe-area.style-2 .subscribe-text .subscribe-form button:hover {
    color: #fff;
    background-color: #d2ae49;
}

.footer-subscibe-area.style-2 .subscribe-text .subscribe-form input {
    padding: 0 40px;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
}

.footer-subscibe-area.style-2 .subscribe-text .subscribe-form input::placeholder {
    color: #fff;
}

.contact-maps {
    height: 700px;
    width: 100%;
}

@media (max-width: 991px) {
    .footer-subscibe-area.style-2 .subscribe {
        text-align: center;
        margin-bottom: 20px;
    }

    .header-two.header-absolute .sigma-mobile-header {
        background-color: transparent;
    }
}

@media (max-width: 767px) {
    .footer-subscibe-area.style-2 .subscribe-text .subscribe-form input {
        margin-bottom: 20px;
    }
}


/*======================
 about
========================*/

.about-style-2 {
    position: relative;
    background-color: #1c1b19;
}

.about-style-2 .about-text-descr {
    text-align: right;
    max-width: 400px;
    margin: 0 30px 0 auto;
    position: relative;
}

.about-style-2 .about-text-descr:after {
    content: "";
    background: #617680;
    height: 100%;
    width: 2px;
    display: block;
    position: absolute;
    right: -45px;
    top: 0;
}

.about-style-2 .section-header {
    padding-left: 30px;
    margin-bottom: 0;
}

.about-style-2 .section-header h5 {
    color: #d2cece;
    margin-bottom: 10px;
}

.dark-bg {
    background-color: #1c1b19;
}

@media (max-width: 991px) {
    .about-style-2 .about-text-descr {
        max-width: 100%;
        margin-bottom: 30px;
        margin-right: 0;
        text-align: left;
    }

    .about-style-2 .about-text-descr:after {
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        bottom: -30px;
        top: auto;
    }

    .about-style-2 .section-header {
        padding-left: 0;
        padding-top: 30px;
    }

    .about-style-2 .section-title-wrap.section-header {
        margin-bottom: 0;
    }
}


/*=======  Shop Section  =======*/

.food-box.shop-box {
    overflow: hidden;
}

.food-box.shop-box:hover .thumb img {
    transform: scale(1.1);
}

.desc .price {
    font-size: 18px;
    font-weight: 700;
    display: block;
    color: #fcd462;
}

.desc .price span {
    text-decoration: line-through;
    font-size: 15px;
    margin-left: 2px;
    color: #808080;
}

.food-box.shop-box .desc h4 {
    margin-bottom: 10px;
    font-size: 18px;
}

.food-box.shop-box .thumb .badges {
    position: absolute;
    top: 10px;
    left: 10px;
    right: auto;
}

.food-box.shop-box .thumb .badges .price {
    font-weight: 500;
    padding: 2px 15px;
    font-size: 16px;
    background-color: #fcd462;
    color: #fff;
    position: static;
    display: block;
}

.shop-box .thumb .badges .price.discounted {
    background-color: #fff;
    padding: 2px 12px;
    color: #1c1b19;
}

.shop-box .thumb .badges span+span {
    margin-top: 10px;
}

.shop-box .thumb .button-group {
    position: absolute;
    right: 10px;
    top: 10px;
}

.shop-box .thumb .button-group a {
    width: 40px;
    height: 40px;
    display: block;
    font-size: 14px;
    line-height: 42px;
    color: #fcd462;
    text-align: center;
    border-radius: 0;
    margin-bottom: 10px;
    background-color: #353530;
    transform: scale(0.6);
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden;
}

.shop-box:hover .thumb .button-group a {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.shop-box .thumb .button-group a:hover {
    color: #fff;
    background-color: #fcd462;
}

.food-box.shop-box .desc .link {
    color: #fcd462;
}


/*------  Shop Section Style 2  ------*/

.food-box.shop-box.shop-box-2 {
    position: relative;
    z-index: 1;
}

.food-box.shop-box.shop-box-2:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
}

.food-box.shop-box.shop-box-2:hover:before {
    background-color: rgba(255, 255, 255, 0.7);
}

.food-box.shop-box-2 .desc {
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.food-box.shop-box-2 .desc .link {
    display: none;
}

.shop-box.shop-box-2 .thumb .button-group a {
    background-color: transparent;
    display: inline-block;
    color: #fff;
}

.shop-box.shop-box-2 .thumb .button-group a:hover {
    background-color: transparent;
    color: #fcd462;
}

.shop-box.shop-box-2 .thumb .button-group {
    top: 5px;
    left: 5px;
    right: auto;
}

.shop-box-2 .thumb .cart-block {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.6);
    transition: all 0.3s ease-out 0s;
}

.shop-box-2:hover .thumb .cart-block {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.shop-box-2 .thumb .cart-block a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.shop-box-2 .thumb .cart-block a:hover {
    color: #fcd462;
}


/*------  Shop Left  ------*/

.widget .irs--flat .irs-handle>i:first-child,
.widget .irs--flat .irs-single {
    background-color: #fcd462;
}

.widget .irs--flat .irs-bar {
    background-color: #fcd462;
    height: 5px;
}

.widget .irs--flat .irs-single:before {
    border-top-color: #fcd462;
}

.widget .irs--flat .irs-line {
    height: 5px;
    background-color: #40403b;
}

.widget .irs--flat .irs-handle {
    top: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fcd462;
}

.widget .filter-color .checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 1.4;
    font-size: 15px;
}

.widget .filter-color .checkbox input,
.color-sec label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.widget .filter-color .checkbox .custom-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #272626;
    border-radius: 0;
    border: 1px solid #444444;
}

.widget .filter-color .checkbox .custom-box:after {
    left: 6px;
    top: 4px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
}

.widget .filter-color .checkbox input:checked~.custom-box:after {
    display: block;
}

.widget .filter-color .checkbox input:checked~.custom-box {
    border: 1px solid #fcd462;
    background-color: #fcd462;
}

.shop-product-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.shop-product-top .sorting-box .nice-select {
    border: solid 1px #40403b;
    height: 60px;
    line-height: 58px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .cart-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-flex .remove {
    font-size: 24px;
    color: #fcd462;
}

.widget-cart-info .cart-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: 600;
    padding-bottom: 25px;
    border-bottom: 1px solid #40403b;
}

.widget-cart-info .cart-buttons {
    padding-top: 25px;
    display: inline-block;
}

.widget-cart-info .cart-buttons a {
    padding: 0 15px;
    line-height: 40px;
}

.widget-cart-info .cart-buttons a:last-child {
    margin-left: 12px;
}

@media (max-width: 339px) {
    .widget-cart-info .cart-buttons a {
        width: 100%;
    }

    .widget-cart-info .cart-buttons a:last-child {
        margin-left: 0;
        margin-top: 12px;
    }
}


/*------  Shop Left style 2  ------*/

.shop-box.style-2 .thumb .badges .price.discounted {
    background-color: #1c1b19;
    color: #fff;
    padding: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
}

.shop-box.style-2 .thumb .button-group {
    top: auto;
    bottom: 10px;
}

.shop-box.style-2 .thumb .button-group a {
    width: 35px;
    height: 35px;
    font-size: 16px;
    line-height: 38px;
}

.shop-box.style-2 .desc .main-btn {
    padding: 0px 22px;
    line-height: 46px;
}

.shop-box.style-2 .thumb .rating {
    position: absolute;
    left: 10px;
    bottom: 10px;
}


/*------  Shop Detail  ------*/

.detail-slider-1 .image-box {
    position: relative;
}

.detail-slider-1 .image-box a,
.detail-slider-1 .image-box a img,
.detail-slider-2 .image-box img {
    width: 100%;
    height: 100%;
}

.detail-slider-1 .image-box .price {
    position: absolute;
    top: 10px;
    background-color: #fcd462;
    color: #fff;
    font-weight: 500;
    padding: 2px 15px;
    font-size: 16px;
    left: auto;
    right: 10px;
}

.shop-detail-image .detail-slider-2 {
    padding-top: 15px;
}

.shop-detail-image .detail-slider-2 .slick-list {
    margin: 0 -5px;
}

.shop-detail-image .detail-slider-2 .slick-list .slick-slide {
    margin: 0 5px;
}

.shop-detail-content .rating {
    display: flex;
    align-items: center;
}

.shop-detail-content .rating .pro-review {
    padding-left: 10px;
}

.shop-detail-content .rating .pro-review span {
    font-size: 12px;
    color: #fff;
}

.shop-detail-content .desc .price {
    font-size: 24px;
}

.shop-detail-content .desc .price span {
    font-size: 18px;
}

.color-sec label {
    margin-bottom: 8px;
    color: #fff;
    font-weight: 500;
    font-weight: 600;
}

.color-sec .color-box {
    line-height: 0;
}

.color-sec .color-box label .choose-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    cursor: pointer;
    margin: 0 5px 5px 0;
    position: relative;
}

.color-sec .color-box label .choose-color.red {
    background-color: #BD2031;
}

.color-sec .color-box label .choose-color.yellow {
    background-color: #cea40e;
}

.color-sec .color-box label .choose-color.blue {
    background-color: #3939c5;
}

.color-sec .color-box label .choose-color.green {
    background-color: #329832;
}

.color-sec .color-box label .choose-color::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0);
    transition: .3s;
    background-color: #353530;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 50%;
    transform-origin: center;
}

.color-sec .color-box label input:checked+span::before {
    transform: scale(1);
}

.color-sec .color-box label .choose-material {
    border-radius: 0;
    display: block;
    background-color: #272626;
    cursor: pointer;
    transition: .3s;
    margin: 0 5px 5px 0;
    line-height: 1;
    font-size: 12px;
    position: relative;
    padding: 10px 20px;
    color: #ffffff;
}

.color-sec .color-box label .choose-material:hover {
    background-color: #fcd462;
    color: #fff;
}

.color-sec .color-box label input:checked+span {
    background-color: #fcd462;
    color: #fff;
}

.shop-detail-content .quantity-cart .main-btn {
    border-color: #a3a3a3;
    color: #a3a3a3;
    border-width: 1px;
}

.shop-detail-content .quantity-cart .main-btn:hover {
    border-color: #fcd462;
    color: #fcd462;
    background-color: transparent;
}

.shop-detail-content .quantity-cart .cart-btn {
    padding-left: 40px;
}

.shop-detail-content .quantity-box {
    width: 215px;
    height: 62px;
    display: flex;
    border: 0;
    border-radius: 0;
}

.shop-detail-content .quantity-box button {
    border: 0px;
    padding: 0 20px;
    background: #272626;
    color: #fff;
    transition: .3s;
}

.shop-detail-content .quantity-box button:hover {
    background-color: #fcd462;
    color: #fff;
}

.shop-detail-content .quantity-box .input-qty {
    border: 0;
    height: auto;
    color: #fff;
    text-align: center;
    background-color: #272626;
    font-size: 15px;
    padding: 0 20px;
    width: 112px;
}

.shop-detail-content .other-info h6 {
    font-size: 14px;
}

.shop-detail-content .other-info .grey {
    color: #a3a3a3;
    font-weight: 400;
    font-size: 14px;
}

.shop-detail-content .other-info a.grey:hover {
    color: #fff;
}

.shop-detail-content .other-info.flex {
    display: flex;
    align-items: center;
}

.shop-detail-content .other-info.flex ul {
    margin-left: 15px;
}

.product-description .nav-tabs {
    border-bottom: 0;
}

.product-description .product-description .nav-tabs .nav-item .nav-link,
.product-description .nav-tabs .nav-link.active,
.product-description .nav-tabs .nav-item .nav-link:not(.active):hover {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: transparent;
}

.product-description .nav-tabs .nav-link.active {
    color: #fcd462;
}

.product-description .nav-tabs .nav-link {
    color: #a3a3a3;
}

.product-description .nav-tabs .nav-link:hover {
    color: #fff;
}

.product-description .tabs .tab-content {
    padding: 20px;
    margin-top: 30px;
}

.input-group.input-group-two input,
.input-group.input-group-two select,
.input-group.input-group-two textarea {
    background-color: #353530;
    border: 0;
}

.product-description .additional-info table th {
    color: #fff;
    font-weight: 600;
}

.product-description .additional-info table th,
.product-description .additional-info table td {
    border: 1px solid #40403b;
    vertical-align: middle;
    color: #fff;
}

.product-description .additional-info table td.value {
    color: #a3a3a3;
}

@media (max-width: 991px) {
    .shop-detail-content {
        margin-top: 60px;
    }
}

@media (max-width: 576px) {
    .shop-detail-content .quantity-box {
        height: 58px;
    }

    .shop-detail-content .quantity-cart .cart-btn {
        padding-left: 0;
        padding-top: 20px;
    }

    .shop-detail-content .other-info.flex {
        display: block;
    }

    .shop-detail-content .other-info.flex ul {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 399px) {
    .product-description .tabs .tab-content {
        padding: 0;
    }
}


/*------ Cart  ------*/

.cw-cart-table thead {
    background: transparent;
    color: #fff;
}

.table thead th,
.table td {
    vertical-align: bottom;
    border: 1px solid #40403b;
}

.table .cw-align {
    vertical-align: middle;
}

.product-remove a i {
    color: #fff;
    text-align: center;
}

.product-thumbnail {
    width: 32px;
    display: inline-block;
    margin-right: 1rem;
}

.btn-link {
    color: grey;
    text-decoration: none;
}

.cw-cart-table input.btn {
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 24px;
    height: auto;
    margin: auto;
    letter-spacing: 0.4px;
    padding: 13px 40px;
    transition: all 0.3s linear 0s;
}

.cw-qty-btn {
    padding: .375rem .75rem;
}

.btn.btn-outline-secondary {
    color: #131313;
    border-color: #131313;
}

.qty {
    width: 40px;
    text-align: center;
    border: 0;
    outline: none;
}

.cw-product-promo {
    border: 1px solid #40403b;
    padding: 1.5rem;
    box-shadow: 0 3px 24px rgba(0, 0, 0, .04);
}

.cw-product-promo h5 {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
}

.cw-product-promo .form-group .form-control {
    background: transparent;
    border: 1px solid #40403b;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    font-size: 13px;
    min-height: 63px;
    line-height: 33px;
    padding: 10px 30px;
    font-weight: 500;
    margin: 0;
}

.cw-product-promo .cw-table-borderless tbody td {
    border: none;
    color: #fff;
}

.cw-qty-sec .quantity-box {
    width: 215px;
    display: flex;
    border: 1px solid #a3a3a3;
    border-radius: 0;
}

.cw-qty-sec .btn {
    cursor: pointer;
    width: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 11px;
    background-color: #fff;
    transition: .3s;
}

.cw-qty-sec .form-control {
    background-color: #fff;
    outline: none;
    width: 60px;
    height: 28px;
    border-radius: 0;
    text-align: center;
    font-weight: 600;
    border: 0;
    font-size: 14px;
    color: #848484;
    line-height: normal;
    padding: 0px 15px;
    box-shadow: none;
}

td.has-title {
    vertical-align: middle;
}

@media (max-width: 991px) {
    .cw-cart-table tbody td.shop-detail-content {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .cw-product-promo .form-group .btn {
        margin: 15px 0 0;
    }

    .cw-cart-table thead {
        display: none;
    }

    .cw-cart-table tbody td {
        position: relative;
        display: block;
        width: 100%;
    }

    .cw-cart-table tbody td.has-title {
        text-align: right;
    }

    .cw-cart-table tbody td.has-title::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        color: #fff;
        float: left;
    }

    .cw-cart-table tfoot .main-btn {
        width: 100%;
    }

    .cw-cart-table tfoot .main-btn.float-right {
        margin-top: 10px;
    }

    .cw-cart-table tbody td.quantity {
        justify-content: space-between;
        display: flex;
    }
}

@media (max-width: 575px) {
    .cw-qty-sec .quantity-box {
        width: 180px;
    }
}


/*------ Wishlist  ------*/

.wishlist .main-btn {
    padding: 0 18px;
    line-height: 52px;
}

.wishlist .share-wishlist h4 {
    margin-bottom: 30px;
    font-weight: 600;
}

.wishlist .share-wishlist .social-media a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    background-color: #353530;
    margin-left: 10px;
}

.wishlist .share-wishlist .social-media a:hover {
    background-color: #fcd462;
}


/*------ Checkout  ------*/

.checkout-form h4 {
    margin-bottom: 30px;
    font-weight: 600;
}

.checkout-form .nice-select .list {
    background-color: #353530;
}

.checkout-form .input-group.input-group-two textarea {
    height: auto;
    padding-top: 25px;
}

.checkout-table table {
    width: 100%;
    margin-bottom: 30px;
}

.checkout-table th,
.checkout-table td {
    border: 1px solid #40403b;
    padding: 10px;
    vertical-align: middle;
}

@media (max-width: 1199px) {
    .space-top {
        margin-top: 50px;
    }
}


/*------ login/Register  ------*/

.login-content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    height: 100%;
    padding: 20px;
}

.login-content:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: -1
}

.login-content .description {
    max-width: 400px;
}

.login-content .description h2 {
    margin-bottom: 20px;
    font-weight: 600;
    color: #fff;
}

.login-form {
    padding: 60px;
    text-align: center;
    background-color: transparent;
    border-top: 1px solid #40403b;
    border-right: 1px solid #40403b;
    border-bottom: 1px solid #40403b;
}

.login-form h2 {
    margin-bottom: 50px;
    color: #fff;
    font-weight: 600;
}

.login-form a {
    text-decoration: underline;
    color: #fff;
    display: block;
}

.login-form a:hover {
    color: #fcd462;
}

.login-form .login-btn {
    line-height: 44px;
    box-shadow: none;
}

.login-form .form-seperator {
    margin: 30px 0;
    text-align: center;
}

.login-form .form-seperator span {
    position: relative;
    display: flex;
    align-items: center;
    color: #a7a2a2;
    font-size: 14px;
}

.login-form .form-seperator span:before {
    content: '';
    flex: 1;
    background-color: rgb(255 245 245 / 55%);
    height: 1px;
    margin-right: 20px;
}

.login-form .form-seperator span:after {
    content: '';
    flex: 1;
    background-color: rgb(255 245 245 / 55%);
    height: 1px;
    margin-left: 20px;
}

.login-form .social-buttons button.main-btn {
    line-height: 45px;
    width: 100%;
    box-shadow: none;
}

.login-form .social-buttons button.main-btn.facebook {
    border: 2px solid #fcd462;
    background-color: transparent;
}

.login-form .social-buttons button i {
    margin-right: 5px;
}

@media (max-width: 991px) {
    .login-content {
        height: 700px;
    }

    .login-form {
        border-left: 1px solid #40403b;
    }
}

@media (max-width: 575px) {
    .login-form {
        padding: 30px;
    }
}


/*------ Account  ------*/

.account-tabs .nav-tabs .nav-item .nav-link {
    background-color: #353530;
    border: 0;
    color: #fff;
    padding: 14px 20px;
    border-radius: 0;
}

.account-tabs .nav-tabs .nav-item+.nav-item .nav-link {
    margin-top: 15px;
}

.account-tabs .nav-tabs .nav-item .nav-link.active,
.account-tabs .nav-tabs .nav-item .nav-link:hover {
    background-color: #fcd462;
}

.account-tabs .nav-tabs .nav-item .nav-link.logout {
    background-color: #ff4764;
    color: #fff;
    border-color: #ff4764;
}

.account-tabs .nav-tabs .nav-item .nav-link.logout i {
    color: #fff;
    font-size: 18px;
    margin-right: 5px;
}

.tab-content .tab-pane {
    width: 100%;
    height: 100%;
}

.tab-content .content-heading h3 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}

.tab-content .order-table a.main-btn {
    padding: 0 20px;
    line-height: 50px;
    box-shadow: none;
}

.tab-content .download-content {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
}

.tab-content .address-content .extra-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-content .address-content .extra-info h3 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
}

.tab-content .address-content .extra-info .btn-link {
    color: #a3a3a3;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
}

.tab-content .address-content .extra-info .btn-link:hover {
    color: #fcd462;
}

@media (max-width: 991px) {
    .account-sec .tab-content {
        margin-top: 60px;
    }
}


/*=======  New Collections  =======*/

.service-wrapper .service-content {
    padding: 30px;
    background-color: #1c1b19;
}

.service-wrapper .service-content .icon {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
}

.service-wrapper .service-content h3 {
    color: #fff;
    margin-bottom: 20px;
}

.service-wrapper .service-content h3 a {
    color: #fff;
}

.service-wrapper .service-content h3 a:hover {
    color: #fcd462;
}


/*-----  Style 2  -------*/

.service-wrapper.style-2 .service-content {
    background-color: #353530;
}


/*=======  Legal  =======*/

.legal-wrapper .legal-content {
    margin-bottom: 40px;
}

.legal-wrapper .legal-content h3 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}


/*=======  FAQ  =======*/

.faq-wrapper .faq-title h3 {
    font-weight: 600;
}

.faq-wrapper .accordion>.card:not(:last-of-type) {
    margin-bottom: 20px;
}

.faq-wrapper .accordion .card {
    background-color: transparent;
    border: 0;
    display: flex;
    flex-wrap: wrap;
}

.faq-wrapper .accordion .card .card-header {
    width: 100%;
    flex-basis: 100%;
    order: 1;
}

.faq-wrapper .accordion .collapseparent {
    order: 2;
}

.faq-wrapper .accordion .card .card-header {
    border-bottom: 1px solid #40403b;
    background-color: transparent;
    color: #fff;
    margin-bottom: 0;
}

.faq-wrapper .accordion .card .card-header .btn {
    color: #fff;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 0;
    position: relative;
    font-family: "Prata", serif;
    font-weight: 400;
    font-size: 16px;
}

.faq-wrapper .accordion .card-header .btn::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    transition: all 0.3s ease-out 0s;
    opacity: 1;
    transform: rotate(90deg);
}

.faq-wrapper .accordion .card-header .btn::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1.25rem;
    width: 10px;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    transition: all 0.3s ease-out 0s;
    opacity: 1;
}

.accordion .collapseparent.show+.card-header .btn::before,
.accordion .card-header .btn::before {
    opacity: 1;
    transform: rotate(0);
}


/*=======  Location  =======*/

.location-wrapper {
    overflow: hidden;
    margin-bottom: 30px;
}

.location-thumb {
    position: relative;
}

.location-thumb:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent 0, transparent 30%, #000);
    opacity: 0.8;
    z-index: 1;
    transition: all 0.3s ease-out 0s;
}

.location-thumb a {
    display: block;
    overflow: hidden;
}

.location-thumb a img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.3s ease-out 0s;
}

.location-thumb:hover a img {
    transform: scale(1.1);
}

.location-thumb .location-content {
    position: absolute;
    bottom: 20px;
    height: auto;
    background-color: transparent;
    padding: 20px;
    z-index: 2;
}

.location-thumb .location-content .icon {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out 0s;
}

.location-thumb .location-content h4 {
    color: #fff;
    margin-bottom: 8px;
    font-weight: 600;
}

.location-thumb .location-content h4 a {
    color: #fff;
}

.location-thumb .location-content span {
    color: #e7e7ef;
}

.location-wrapper:hover .location-thumb:before {
    background: linear-gradient(180deg, #9e7f27 0, #fcd462 30%, #b08f33);
    opacity: 1;
}

.location-thumb:hover .location-content h4 a,
.location-thumb:hover .location-content span {
    color: #000;
}

.location-thumb:hover .location-content .icon {
    visibility: visible;
    opacity: 1;
}

.container-wide .row {
    flex-wrap: unset;
    align-items: center;
}

@media (max-width: 991px) {
    .container-wide .row {
        flex-wrap: wrap;
    }
}


/*=======  Intro Wrapper  =======*/


/*=======  FOOTER  =======*/

footer .footer-subscibe-area {
    background-color: #353530;
}

footer .footer-subscibe-area .subscribe-text .subscribe-form {
    position: relative;
}

footer .footer-subscibe-area .subscribe-text .subscribe-form input {
    width: 100%;
    border: none;
    height: 90px;
    background-color: #1c1b19;
    padding-left: 50px;
    padding-right: 230px;
    font-size: 14px;
    color: #aeaeae;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .footer-subscibe-area .subscribe-text .subscribe-form input {
        padding-left: 20px;
        padding-right: 200px;
    }
}

@media (max-width: 575px) {
    footer .footer-subscibe-area .subscribe-text .subscribe-form input {
        padding-left: 20px;
        padding-right: 20px;
        height: 70px;
    }
}

footer .footer-subscibe-area .subscribe-text .subscribe-form button {
    position: absolute;
    right: 15px;
    bottom: 15px;
    top: 15px;
    background-color: #fcd462;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    width: 200px;
    letter-spacing: 3px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .footer-subscibe-area .subscribe-text .subscribe-form button {
        width: 180px;
    }
}

@media (max-width: 575px) {

    footer .footer-subscibe-area .subscribe-text .subscribe-form button,
    footer .footer-subscibe-area.style-2 .subscribe-text .subscribe-form button {
        position: unset;
        width: 100%;
        height: 60px;
    }
}

footer .footer-subscibe-area .subscribe-text .subscribe-form button:hover {
    background-color: #fff;
    color: #1c1b19;
}

footer .footer-widget-area {
    position: relative;
    z-index: 1;
}

footer .footer-widget-area::after,
.sigma-footer::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/icon/pattern-01.png);
    content: '';
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    z-index: -1;
}

@media(min-width: 1600px) {
    .sigma-footer .container-fluid {
        padding-left: 65px;
        padding-right: 65px;
    }
}

footer .footer-widget-area .widget,
footer .footer-widget-area .widget a,
footer .footer-widget-area .widget .widget-title,
footer .footer-widget-area .widget h6 {
    color: #fff;
}

footer .footer-widget-area .widget h4.widget-title {
    font-size: 24px;
    margin-bottom: 4px;
    margin-bottom: 40px;
}

footer .footer-widget-area .widget.site-info-widget {
    color: #bababa;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    footer .footer-widget-area .widget.site-info-widget {
        font-size: 14px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .footer-widget-area .widget.site-info-widget {
        font-size: 14px;
    }
}

footer .footer-widget-area .widget.site-info-widget .social-links {
    text-align: left;
}

footer .footer-widget-area .widget.site-info-widget .social-links a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background-color: #2e2e2e;
    margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    footer .footer-widget-area .widget.site-info-widget .social-links a {
        height: 35px;
        width: 35px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 2px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .footer-widget-area .widget.site-info-widget .social-links a {
        height: 35px;
        width: 35px;
        line-height: 35px;
        font-size: 14px;
        margin-right: 2px;
    }
}

footer .footer-widget-area .widget.site-info-widget .social-links a:hover {
    background-color: #fcd462;
}

footer .footer-widget-area .widget.nav-widget {
    padding-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    footer .footer-widget-area .widget.nav-widget {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    footer .footer-widget-area .widget.nav-widget {
        padding-left: 0;
    }
}

footer .footer-widget-area .widget.nav-widget ul li {
    margin-bottom: 35px;
    width: 50%;
    float: left;
}

@media (max-width: 399px) {
    footer .footer-widget-area .widget.nav-widget ul li {
        margin-bottom: 20px;
        width: 100%;
    }
}

footer .footer-widget-area .widget.nav-widget ul li a {
    font-family: "Roboto", sans-serif;
    position: relative;
    display: flex;
    align-items: center;
}

footer .footer-widget-area .widget.nav-widget ul li a:hover {
    padding-left: 10px;
    color: #fcd462;
}

footer .footer-widget-area .widget.nav-widget ul li a::before {
    content: '\f108';
    font-family: flaticon;
    font-size: 20px;
    line-height: 1;
    margin-right: 10px;
}

footer .footer-widget-area .widget.nav-widget ul li:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    footer .footer-widget-area .widget.contact-widget {
        padding-left: 70px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .footer-widget-area .widget.contact-widget {
        padding-left: 20px;
    }
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #2e2e2e;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 40px 1fr;
    grid-template-columns: 40px 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box {
        grid-column-gap: 15px;
    }
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box h6 {
    font-size: 20px;
    margin-bottom: 10px;
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box .icon {
    font-size: 40px;
    color: #fcd462;
}

footer .footer-widget-area.light-theme {
    background-image: url(../img/icon/pattern-02.png);
}

footer .footer-widget-area.light-theme::after {
    display: none;
}

footer .footer-widget-area.light-theme .widget,
footer .footer-widget-area.light-theme .widget a {
    color: #a3a3a3;
}

footer .footer-widget-area.light-theme .widget .widget-title,
footer .footer-widget-area.light-theme .widget h6 {
    color: #353530;
}

footer .footer-widget-area.light-theme .widget.site-info-widget .social-links a {
    background-color: #1c1b19;
    color: #a3a3a3;
}

footer .footer-widget-area.light-theme .widget.site-info-widget .social-links a:hover {
    background-color: #fcd462;
}

footer .footer-widget-area.light-theme .widget.contact-widget .contact-box {
    border-color: #e1e1e1;
}

footer .copyright-area {
    color: #fff;
}

footer .copyright-area .social-links {
    text-align: right;
}

@media (max-width: 767px) {
    footer .copyright-area .social-links {
        text-align: center;
        margin-bottom: 10px;
    }
}

footer .copyright-area .social-links a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    background-color: #212121;
    margin-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    footer .copyright-area .social-links a {
        margin-left: 5px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    footer .copyright-area .social-links a {
        margin: 0 5px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
}

@media (max-width: 575px) {
    footer .copyright-area .social-links a {
        margin: 0 2px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
    }
}

footer .copyright-area .social-links a:hover {
    background-color: #fcd462;
}

footer .copyright-area .copyright-text {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
}

@media (max-width: 575px) {
    footer .copyright-area .copyright-text {
        letter-spacing: 0;
    }
}

footer .copyright-area .copyright-text a {
    color: #fff;
    text-decoration: underline;
}

footer .copyright-area .copyright-text.copyright-two {
    text-transform: none;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    letter-spacing: 0;
}

@media (max-width: 767px) {
    footer .copyright-area .copyright-text {
        margin-top: 10px;
        text-align: center;
    }
}

footer .copyright-area .footer-menu ul li {
    display: inline-block;
    position: relative;
    padding-left: 15px;
    margin-right: 15px;
}

@media (max-width: 399px) {
    footer .copyright-area .footer-menu ul li {
        padding-left: 0;
        margin-right: 0;
    }
}

footer .copyright-area .footer-menu ul li::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 3px;
    height: 12px;
    background-color: #353530;
    content: '';
}

@media (max-width: 399px) {
    footer .copyright-area .footer-menu ul li::before {
        display: none;
    }
}

footer .copyright-area .footer-menu ul li:first-child {
    padding-left: 0;
    margin-left: 0;
}

footer .copyright-area .footer-menu ul li:first-child::before {
    display: none;
}

footer .copyright-area .footer-menu ul li a {
    color: #fff;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
}

footer .copyright-area .footer-menu ul li a:hover {
    color: #fcd462;
}

footer .copyright-area.light-theme {
    background-color: #1c1b19;
}

footer .copyright-area.light-theme,
footer .copyright-area.light-theme a,
footer .copyright-area.light-theme .copyright-text,
footer .copyright-area.light-theme .footer-menu ul li a {
    color: #fff;
}

footer .copyright-area.light-theme .footer-menu ul li::before {
    background-color: #737373;
}


/*=======  Sigma Footer  =======*/

.sigma-footer {
    position: relative;
    z-index: 1;
}

.sigma-footer .sigma-footer-top .sigma-backto-top {
    width: 100%;
    padding: 40px;
}

.sigma-footer .sigma-footer-top .sigma-backto-top .back-to-top {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: auto;
    background: transparent;
    height: auto;
    line-height: initial;
    top: 0;
    bottom: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    right: 0;
}

.sigma-footer .sigma-footer-top .sigma-backto-top .back-to-top:hover i {
    color: #fff;
}

.sigma-footer .sigma-footer-top .sigma-backto-top .back-to-top i {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background-color: #fcd462;
}

.sigma-footer .contact-maps {
    height: 100%;
}

.sigma-footer .sigma-footer-box {
    background-color: #353530;
    padding: 40px;
    padding-bottom: 25px;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-logo,
.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media {
    display: flex;
    margin-bottom: 40px;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media>li {
    margin-left: 10px;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media>li>a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background-color: #2e2e2e;
    margin-right: 5px;
}

.sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media>li:hover>a {
    background-color: #fcd462;
}

.sigma-footer .sigma-copyright-inner a:hover,
.sigma-footer .sigma-footer-contact ul>li:hover>a {
    color: #fcd462;
}

.sigma-footer .sigma-footer-nav .ft-nav {
    display: flex;
}

.sigma-footer .sigma-footer-nav .ft-nav>.menu-item {
    margin-bottom: 15px;
    margin-right: 25px;
}

.sigma-footer .sigma-footer-nav .ft-nav>.menu-item>a {
    color: #fff;
    font-family: "Roboto", sans-serif;
    position: relative;
    display: flex;
    align-items: center;
}

.sigma-footer .nice-select .list {
    background-color: #1c1b19;
}

.sigma-footer .sigma-footer-nav .ft-nav>.menu-item>a:before {
    content: '\f108';
    font-family: flaticon;
    font-size: 16px;
    line-height: 1;
    margin-right: 10px;
}

.sigma-footer .sigma-footer-nav .ft-nav>.menu-item:hover>a {
    padding-left: 10px;
    color: #fcd462;
}

.sigma-footer .sigma-footer-bottom .sigma-footer-bottom-inner {
    background-color: #353530;
    padding: 40px;
    padding-bottom: 25px;
}

.sigma-footer .sigma-footer-contact ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.sigma-footer .sigma-footer-contact ul>li {
    margin-bottom: 15px;
    margin-right: 40px;
    display: flex;
    align-items: center;
}

.sigma-footer .sigma-footer-contact ul>li>a {
    color: #fff;
}

.sigma-footer .sigma-footer-contact ul>li>i {
    color: #fcd462;
    text-align: center;
    vertical-align: middle;
    font-size: 40px;
    line-height: normal;
    margin-right: 15px;
}

.sigma-footer .sigma-footer-contact ul>li>a span {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: "Prata", serif;
    font-weight: 400;
    display: flex;
}

.sigma-footer .sigma-footer-contact.style-2 ul>li>a span,
.sigma-footer .sigma-footer-contact.style-2 ul,
.sigma-footer .sigma-footer-contact.style-2 ul>li {
    margin-right: 0;
    flex-direction: row-reverse;
}

.sigma-footer .sigma-footer-contact.style-2 ul>li>i {
    margin-right: 0;
    margin-left: 15px;
}

.sigma-footer .sigma-footer-search {
    margin-bottom: 15px;
}

.sigma-footer .sigma-footer-search .form-control {
    height: 50px;
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #40403b;
    padding-left: 50px;
    color: #fff;
}

.sigma-footer .sigma-footer-search .form-control:focus {
    box-shadow: none;
    border-bottom-color: #fff;
}

.sigma-footer .sigma-footer-search .form-control::placeholder {
    color: #fff;
}

.sigma-footer .sigma-footer-search .input-group-prepend {
    position: absolute;
    height: 50px;
    width: 50px;
    left: 0;
    z-index: 1;
}

.sigma-footer .sigma-footer-search .input-group-prepend>button {
    width: 100%;
    border: none;
    background: transparent;
    color: #a3a3a3;
    position: relative;
}

.sigma-footer .sigma-copyright-inner {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #353530;
}

.sigma-footer .sigma-copyright-inner p {
    margin-bottom: 40px;
    text-transform: none;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 14px;
}

.sigma-footer .sigma-copyright-inner a {
    color: #fff;
    text-decoration: underline;
    margin-left: 4px;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li {
    margin-left: 15px;
    padding-left: 15px;
    margin-bottom: 40px;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li>a {
    text-decoration: none;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    margin-left: 0;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li:after {
    content: '';
    width: 3px;
    height: 12px;
    background: #40403b;
    position: absolute;
    top: 8px;
    margin-left: 15px;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li:last-child:after {
    display: none;
}

.sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li:first-child {
    padding-left: 0;
    margin-left: 0;
}

@media (max-width: 1200px) {
    .sigma-footer .sigma-footer-contact ul>li {
        margin-right: 10px;
    }

    .sigma-footer .sigma-footer-contact ul>li>a {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .sigma-footer .contact-maps {
        height: 350px;
    }

    .sigma-footer .sigma-footer-contact ul {
        justify-content: center;
        text-align: center;
    }

    .sigma-footer .sigma-footer-contact.style-2 ul>li>a span,
    .sigma-footer .sigma-footer-contact.style-2 ul,
    .sigma-footer .sigma-footer-contact.style-2 ul>li {
        margin-right: 0;
        flex-direction: unset;
    }

    .sigma-footer .sigma-footer-contact.style-2 ul>li>i {
        margin-right: 15px;
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .sigma-footer .sigma-footer-nav .ft-nav {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul,
    .sigma-footer .sigma-footer-contact ul {
        justify-content: flex-start;
        text-align: left;
    }
}

@media (max-width: 576px) {

    .sigma-footer .sigma-copyright-inner,
    .sigma-footer .sigma-footer-top .sigma-backto-top {
        padding: 20px;
    }

    .sigma-footer .sigma-footer-bottom .sigma-footer-bottom-inner,
    .sigma-footer .sigma-footer-box {
        padding: 20px;
        padding-bottom: 5px;
    }

    .sigma-footer .sigma-copyright-inner .sigma-copyright-menu>ul>li,
    .sigma-footer .sigma-copyright-inner p,
    .sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-logo,
    .sigma-footer .sigma-footer-box .sigma-footer-box-top .ft-social-media {
        margin-bottom: 20px;
    }
}


/*=======  Back to top  =======*/

.back-to-top {
    position: fixed;
    right: 0;
    bottom: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fcd462;
    z-index: 99;
    color: #fff;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
    right: 40px;
}

.back-to-top:hover {
    background-color: #fff;
}

.alert.d-block {
    display: block !important;
}

.video-react {
    background-color: transparent !important;
}

.moving-strip-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.moving-strip-content {
    white-space: nowrap;
    animation: moveLeft 10s linear infinite;
    font-size: 18px;
}

/* 
.moving-strip-content p {
} */

@keyframes moveLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width:400px) {
    @keyframes moveLeft {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-200%);
        }
    }

}

.home-slider {
    height: 70vh;
}

.home-slider img {
    object-fit: cover;
}


.video-react .video-react-video {
    object-fit: cover;
}

@media (max-width: 399px) {
    .home-slider {
        height: 55vh;
    }
}

.video-react .video-react-big-play-button {
    transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
    .breadcrumb-area .breadcrumb-text span {
        font-size: 35px;
    }
}

p {
    font-size: 18px;
}