/*=======  CALL TO ACTIONS  =======*/
.cta-section {
	.cta-inner {
		padding: 100px 0;
		background-color: $white;
		position: relative;
		z-index: 1;
		.cta-text {
			p {
				font-size: 20px;
				line-height: 1.6;
				color: #a08d69;
			}
			a.main-btn.btn-filled {
				background-color: $black;
				color: $white;
				margin-top: 40px;
				border-color: $black;
				&:hover {
					background-color: $primary-color;
					border-color: $primary-color;
				}
			}
			@media #{$md} {
				margin-top: 60px;
			}
			@media #{$sm} {
				margin-top: 60px;
			}
		}
		.cta-features {
			.single-feature {
				display: grid;
				grid-template-rows: 1fr;
				grid-template-columns: 120px 1fr;
				@media #{$lg} {
					grid-template-columns: 100px 1fr;
				}
				@media #{$lsm} {
					grid-template-columns: 100px 1fr;
				}
				@media #{$xsm} {
					display: block;
				}
				margin-bottom: 45px;
				.icon {
					width: 120px;
					height: 120px;
					border-radius: 50%;
					background-color: $white;
					box-shadow: 0px 8px 16px 0px rgba(190, 173, 142, 0.2);
					text-align: center;
					line-height: 120px;
					font-size: 60px;
					color: $primary-color;
					@include transition(0.3s);
					position: relative;
					z-index: 2;

					@media #{$lg} {
						height: 100px;
						width: 100px;
						line-height: 100px;
						font-size: 40px;
					}

					@media #{$sm} {
						height: 100px;
						width: 100px;
						line-height: 100px;
						font-size: 40px;
					}

					@media #{$xsm} {
						margin-bottom: 30px;
					}
				}
				.cta-desc {
					position: relative;
					padding-left: 40px;
					z-index: 1;
					font-size: 14px;
					@media #{$xsm} {
						padding-left: 0;
					}
					h3 {
						font-size: 30px;
						color: $black;
						margin-bottom: 20px;
						letter-spacing: -1px;
						@media #{$lg} {
							font-size: 26px;
						}
					}
					.count {
						position: absolute;
						font-size: 120px;
						font-weight: 700;
						left: -32px;
						top: 0;
						line-height: 1;
						-webkit-text-fill-color: transparent;
						-webkit-text-stroke: 1px $black;
						z-index: -1;
						color: transparent;
						opacity: 0.1;
						@media #{$xsm} {
							left: 0;
							top: -75px;
						}
					}
				}
				&:hover .icon {
					background-color: $primary-color;
					color: $white;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&::after {
			content: '';
			position: absolute;
			left: 35px;
			right: 35px;
			bottom: -40px;
			background-color: $primary-color;
			height: 20px;
		}
		&::before {
			content: '\f127';
			font-family: 'Flaticon';
			left: 0;
			bottom: 0;
			line-height: 1;
			color: #f6f6f6;
			font-size: 350px;
			position: absolute;
		}
	}
}

/*=======  Call TO Action Two  =======*/
.room-gallery-cta {
	position: relative;
	z-index: 1;
	background-size: cover;
	background-position: center;
	padding: 120px 0;
	background-attachment: fixed;

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $black;
		opacity: 0.7;
		content: '';
	}

	ul li {
		display: inline-block;
		margin: 0px 10px;
		@media #{$sm} {
			margin: 0 5px;
		}

		.main-btn {
			@media #{$sm} {
				padding: 0 25px;
				letter-spacing: 1px;
			}

			@media #{$tiny} {
				padding: 0 15px;
				letter-spacing: 1px;
				font-size: 12px;
			}

			&.btn-filled,
			&.btn-border:hover {
				box-shadow: none;
			}
			&.btn-filled:hover,
			&.btn-border {
				border-color: rgba($color: #fff, $alpha: 0.2);
				color: $white;
			}
		}
	}
	.cta-text {
		h2 {
			font-size: 80px;
			line-height: 1.125;
			letter-spacing: -3px;
			color: $white;
			@media #{$xl} {
				font-size: 72px;
			}
			@media #{$lg} {
				font-size: 60px;
			}
			@media #{$md} {
				font-size: 52px;
			}
			@media #{$lsm} {
				font-size: 42px;
			}

			@media #{$xsm} {
				font-size: 32px;
			}
			@media #{$tiny} {
				font-size: 28px;
			}
		}
		span {
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 3px;
			padding-bottom: 20px;
			color: $primary-color;
		}
	}

	.rotate-images {
		img {
			position: absolute;
			z-index: 1;
			bottom: 0;
			transform: rotate(-37deg);
			left: 3%;
			z-index: -1;
			@media #{$sm} {
				max-width: 150px;
			}
			@media #{$tiny} {
				max-width: 100px;
			}
			&.rotate-image-one {
				left: 8%;
				top: 0;
				bottom: auto;
				max-width: 260px;
				transform: rotate(-37deg);
				@media #{$xl} {
					left: 2%;
				}
				@media #{$lg} {
					left: 2%;
					max-width: 220px;
				}
				@media #{$md} {
					left: 1%;
					max-width: 180px;
				}
				@media #{$sm} {
					left: 1%;
					max-width: 150px;
				}
				@media #{$tiny} {
					max-width: 100px;
				}
			}
			&.rotate-image-three {
				right: 5%;
				left: auto;
				@media #{$xl} {
					left: auto;
					right: 2%;
					max-width: 250px;
				}
				@media #{$lg} {
					left: auto;
					right: 2%;
					max-width: 250px;
				}
				@media #{$md} {
					left: auto;
					right: 1%;
					max-width: 200px;
				}
				@media #{$sm} {
					left: auto;
					right: 1%;
					max-width: 150px;
				}
				@media #{$tiny} {
					max-width: 100px;
				}
			}
		}
	}
}
